import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect, useLape } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import Loader from '@components/CommonSC/Loader'
import * as SpecialisationGeneral from '@src/pages/Forms/SpecialisationForm/General'
import * as SpecialisationCompetencyMatrix from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/CompetencyMatrix'
import * as SpecialisationHiringProcess from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcess'
import * as Postings from '@src/pages/Forms/SpecialisationForm/Postings/Postings'
import * as CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import Talent from '@src/pages/Forms/SpecialisationForm/Talent/Talent'
import { SpecialisationInterface } from '@src/interfaces/roles'
import * as Requisitions from '@src/pages/Forms/SpecialisationForm/Requisitions/Requisitions'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { specialisationsRequestsNew, specialisationTalentTabStats } from '@src/api/roles'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, chain, Icon } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import { PageWrapper } from '@components/Page/Page'
import { Status } from '@components/CommonSC/General'
import { Statuses } from '@src/interfaces'
import upperFirst from 'lodash/upperFirst'
import * as Preview from '@src/pages/Forms/SpecialisationForm/Preview/Preview'
import { useGetMatrixValidatorAndSenioritiesForSpecialisation } from '@src/features/CompetencyMatrixTable/utils'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { Engagement } from './Engagement/Engagement'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { HiringProcessesPage } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/HiringProcessesPage'
import { useCustomHiringProcessFlag } from './HiringProcesses/utils'
import { AnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'

const Specialisation = () => {
  const params = useParams<{ id?: string }>()
  const permissions = useSelector(selectPermissions)
  const { values, initialValues, valid, dirty, isSubmitting } =
    useLapeContext<SpecialisationInterface>()
  const customHiringProcesses = useCustomHiringProcessFlag()
  const entity = useMemo<OrgEntityInterface | undefined>(
    () => (values.id ? { type: EntityTypes.specialisation, data: values } : undefined),
    [values.id],
  )
  const featureFlags = useSelector(selectFeatureFlags)

  const state = useLape<{
    loading: boolean
    stats?: TalentStatsInterface
  }>({
    loading: !!params.id,
    stats: undefined,
  })

  const backUrl = values?.role?.id
    ? pathToUrl(ROUTES.FORMS.ROLE.SPECIALISATIONS, { id: values?.role?.id })
    : ROUTES.ORGANISATION.ROLES.SPECIALISATIONS

  useEffect(() => {
    if (params.id) {
      specialisationTalentTabStats(params.id).then(res => {
        state.stats = res.data
        state.loading = false
      })
    }
  }, [])

  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: {
      engagement_enabled,
      job_postings_enabled,
      candidates_enabled,
      requisitions_enabled,
    },
  } = useGlobalSettings()

  const { matrixValidated } = useGetMatrixValidatorAndSenioritiesForSpecialisation(
    values,
    undefined,
    true,
  )

  if (state.loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const isNewInstance = !values?.id || values.status === Statuses.draft
  const isHiringProcessFilled = matrixValidated && !!values?.hiring_process_rounds?.length

  const tabs = [
    {
      title: 'General',
      path: ROUTES.FORMS.SPECIALISATIONS.GENERAL,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, params),
      preTitle: <ValidationPre isVisible={isNewInstance} isValid={valid} />,
      component: SpecialisationGeneral.default,
      canView: isNewInstance,
    },
    {
      title: 'Preview',
      path: ROUTES.FORMS.SPECIALISATIONS.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
        ...params,
        subtab: customHiringProcesses ? values.default_hiring_process?.id : undefined,
      }),
      component: Preview.default,
      canView: !isNewInstance,
    },
    {
      title: 'Competency matrix',
      path: ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX, params),
      disabled: isNewInstance && !valid,
      preTitle: (
        <ValidationPre isVisible={isNewInstance && valid} isValid={matrixValidated} />
      ),
      component: SpecialisationCompetencyMatrix.default,
    },
    {
      title: 'Hiring process',
      path: ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS,
      disabled: isNewInstance && !matrixValidated,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
        ...params,
        subtab: customHiringProcesses ? values.default_hiring_process?.id : undefined,
      }),
      preTitle: (
        <ValidationPre
          isVisible={isNewInstance && matrixValidated}
          isValid={isHiringProcessFilled}
        />
      ),
      component:
        isNewInstance || !customHiringProcesses
          ? SpecialisationHiringProcess.default
          : HiringProcessesPage,
      canView:
        values?.field_options?.permissions?.includes(
          PermissionTypes.ViewHiringProcessSpecialisation,
        ) && candidates_enabled,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: <Icon name="BarChart" size={15} />,
      path: ROUTES.FORMS.SPECIALISATIONS.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView:
        !isNewInstance &&
        !featureFlags.includes(FeatureFlags.CommercialProduct) &&
        featureFlags.includes(FeatureFlags.AnalyticsInRolesEnabled),
      quickSummary: <QuickSummaryCount count={values?.dashboard_count} />,
    },
    {
      title: 'Postings',
      path: ROUTES.FORMS.SPECIALISATIONS.POSTINGS,
      disabled: isNewInstance && !isHiringProcessFilled,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, params),
      preTitle: (
        <ValidationPre
          isVisible={isNewInstance && isHiringProcessFilled}
          isValid={isHiringProcessFilled}
        />
      ),
      quickSummary: <QuickSummaryCount count={values.posting_count} />,
      component: Postings.default,
      canView: job_postings_enabled,
    },
    {
      title: 'Preview',
      path: ROUTES.FORMS.SPECIALISATIONS.PREVIEW,
      disabled: isNewInstance && !isHiringProcessFilled,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, params),
      component: Preview.default,
      canView: isNewInstance,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.SPECIALISATIONS.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.TALENT.ANY, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: values?.headcount,
        nips: state.stats?.nips,
      }),
      component: Talent,
      canView: !isNewInstance,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.SPECIALISATIONS.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.REQUISITIONS, params),
      quickSummary: <QuickSummaryCount count={values.requisition_headcount} />,
      component: Requisitions.default,
      canView: !isNewInstance && requisitions_enabled,
    },
    {
      title: 'Candidates',
      path: ROUTES.FORMS.SPECIALISATIONS.CANDIDATES,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.CANDIDATES, params),
      component: CommonCandidatesTable.default,
      quickSummary: <QuickSummaryCount count={values.candidate_count} />,
      canView:
        !isNewInstance &&
        candidates_enabled &&
        permissions.includes(PermissionTypes.ViewCandidate),
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.CATEGORIES, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !isNewInstance &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  const showStatus =
    !!initialValues.status &&
    (!!settings?.enable_specialisations_approvals ||
      initialValues.status === Statuses.archived)

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={chain(
            values.name || 'New Specialisation',
            showStatus && (
              <Status status={initialValues.status as Statuses}>
                {upperFirst(initialValues.status)}
              </Status>
            ),
          )}
          backUrl={backUrl}
        >
          <Box pb="s-16" maxWidth="100vw">
            <TabBarNavigation
              isDisabled={isNewInstance && dirty}
              disabledTooltip="Please save your changes."
              key={`${isSubmitting}`}
              tabs={filteredTabs}
            />
          </Box>
        </PageHeader>

        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component
                data={values}
                type="specialisation"
                matrixValidated={matrixValidated}
              />
            </Route>
          ))}
          {filteredTabs[0]?.path ? <InternalRedirect to={filteredTabs[0].path} /> : null}
        </Switch>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

const SpecialisationWrapper = () => {
  return (
    <Form api={specialisationsRequestsNew}>
      <Specialisation />
    </Form>
  )
}
export default connect(SpecialisationWrapper)
