const MAIN = '/'
const LOGIN = '/login'
export const SIGNUP = '/signup'
const PINNED = '/pinned'
const PEOPLE = '/people'
const RECRUITMENT = '/recruitment'
const ADMIN = '/admin'
const ORGANISATION = '/organisation'
const FEATURES = '/features'
const FUNCTION = '/functions'
const PERFORMANCE = '/performance'
const APPS = '/apps'
const SETTINGS = '/settings'
const TICKETS = '/tickets'
const ONBOARDING_CHECKLIST = '/onboarding-checklist'
const ONBOARDING_CHECKLIST_V2 = `${ONBOARDING_CHECKLIST}-v2`
const PREFERENCES = '/preferences'
const PLATFORM_ONBOARDING = '/platform-onboarding'
const CAREERS = '/careers'
export const ONBOARDING = '/onboarding'
export const ONBOARDING_V2 = `${ONBOARDING}-v2`
export const PUBLIC_PREFIX = '/public'
export const WORKSPACES = {
  ANY: '/workspace/:subroute?',
  MAIN: '/workspace',
  FIND: '/workspace/find',
}

export const ROUTES = {
  ANY: '/*',
  MAIN,
  PINNED: `${PINNED}/:id/:subtabId?`,
  IAP: '/iap',
  FORMS: {
    DATA_RETENTION: {
      ANY: `/data-retention/:action/:countryId?/:id?`,
      GENERAL: `/data-retention/edit/:countryId/:id?`,
      PREVIEW: `/data-retention/preview/:countryId/:id`,
    },
    BULK_EDIT_EMPLOYEES: {
      ANY: `/bulk-edit/employees/:id?/:tab?`,
      SELECT: `/bulk-edit/employees/new/select`,
      EDIT: `/bulk-edit/employees/new/edit`,
      REVIEW: `/bulk-edit/employees/:id/review`,
      TRACK: `/bulk-edit/employees/:id/track`,
    },
    BULK_EDIT_CONTRACTS: {
      ANY: `/bulk-edit/contracts/:id?/:tab?`,
      SELECT: `/bulk-edit/contracts/new/select`,
      EDIT: `/bulk-edit/contracts/new/edit`,
      REVIEW: `/bulk-edit/contracts/:id/review`,
      TRACK: `/bulk-edit/contracts/:id/track`,
    },
    OPTION_EXERCISE: {
      ANY: `/option-exercise/:id/:tab`,
      PAYMENTS: `/option-exercise/:id/payments`,
    },
    PERMISSION_MANAGEMENT: {
      ANY: '/permission/:entity/:entityId/:tab/:id?',
      TABLE: '/permission/:entity/:entityId/table',
      FORM: '/permission/:entity/:entityId/form/:id?',
      ENTITIES: {
        DEPARTMENT: '/permission/department/:entityId/:tab/:id?',
        FUNCTION: '/permission/function/:entityId/:tab/:id?',
        ROLE: '/permission/role/:entityId/:tab/:id?',
        SPECIALISATION: '/permission/specialisation/:entityId/:tab/:id?',
        TEAM: '/permission/team/:entityId/:tab/:id?',
        CANDIDATE: '/permission/candidate/:entityId/:tab/:id?',
        REQUISITION: '/permission/requisition/:entityId/:tab/:id?',
        CONNECTION: '/permission/connection/:entityId/:tab/:id?',
        QUERY: '/permission/query/:entityId/:tab/:id?',
        DASHBOARD: '/permission/dashboard/:entityId/:tab/:id?',
      },
    },
    /** @deprecated */
    SEND_ACTIVATION_EMAILS: `/send-activation-emails`,
    EMPLOYEE: {
      ANY: `/employees/:tab/:id?/:subtab?/:new?`,
      PROFILE: `/employees/profile/:id?`,
      PREVIEW: `/employees/preview/:id?`,
      NEW: `/employees/new`,
      GENERAL: {
        ANY: `/employees/general/:id?/:subtab?/:new?`,
        NAME: `/employees/general/:id?/name`,
        POSITION: `/employees/general/:id?/position`,
        ORGANISATION: `/employees/general/:id?/organisation`,
        NOTES: `/employees/general/:id?/notes`,
        BIO: `/employees/general/:id?/bio`,
        CONTACT_INFO: `/employees/general/:id?/contact-info`,
        EMERGENCY_CONTACTS: `/employees/general/:id?/emergency-contacts`,
        LAPTOP_DELIVERY: `/employees/general/:id?/laptop-delivery`,
        IDENTIFICATION: `/employees/general/:id?/identification`,
        CORRESPONDENCE_ADDRESS: `/employees/general/:id?/correspondence-address`,
        ABOUT: `/employees/general/:id?/about`,
        CONTRACT: `/employees/general/:id?/contract`,
        SCREENING: `/employees/general/:id?/screening`,
        BANK_DETAILS: `/employees/general/:id?/bank-details`,
        KEY_PERSON_RESPONSIBILITIES: `/employees/general/:id/key-person-responsibilities`,
        KEY_PERSON_RESPONSIBILITY_CHANGELOG: `/employees/general/:id/key-person-responsibility-changelog/:responsibilityId?`,
        DIVERSITY: `/employees/general/:id?/diversity`,
        TERMINATION_V2: `/employees/general/:id?/termination-v2/:new?`,
        TERMINATION: `/employees/general/:id?/termination/:new?`,
        CHANGE_PASSWORD: `/employees/general/:id?/change-password`,
        PIP: `/employees/general/:id?/pip/:new?`,
        RIGHT_TO_WORK: `/employees/general/:id?/right-to-work`,
        LINKED_ACCOUNTS: `/employees/general/:id?/linked-accounts`,
        ACTION_ITEMS: `/employees/general/:id?/action-items`,
      },
      /** @deprecated */
      TALENT: {
        ANY: `/employees/talent/:id?/:subtab?`,
        DIRECT_REPORTS: `/employees/talent/:id?/direct-reports`,
        FUNCTIONAL_REPORTS: `/employees/talent/:id?/functional-reports`,
        DIRECT_REPORTS_PERFORMANCE: `/employees/talent/:id?/direct-reports-performance`,
        FUNCTIONAL_REPORTS_PERFORMANCE: `/employees/talent/:id?/functional-reports-performance`,
        HIERARCHY: `/employees/talent/:id?/hierarchy`,
      },
      AUDIT: `/employees/audit/:id?`,
      HIRING: `/employees/hiring/:id?`,
      ROADMAP: `/employees/roadmap/:id?`,
      REWARDS: {
        ANY: `/employees/rewards/:id?/:subtab?/:new?`,
        SUMMARY: `/employees/rewards/:id?/summary`,
        SALARY: `/employees/rewards/:id?/salary`,
        SALARY_SACRIFICE: `/employees/rewards/:id?/salary-sacrifice`,
        BENEFITS: `/employees/rewards/:id?/benefits`,
        BONUSES: `/employees/rewards/:id?/bonuses`,
      },
      EQUITY_OVERVIEW: `/employees/equity-overview/:id?`,
      PAYROLL: `/employees/payroll/:id?`,
      GROUPS: `/employees/groups/:id?`,
      PERFORMANCE_SUMMARY: `/employees/performance-summary/:id?`,
      PERFORMANCE: {
        ANY: `/employees/performance/:id?/:subtab?`,
        PERFORMANCE: `/employees/performance/:id?/performance`,
        UPWARDS: `/employees/performance/:id?/upwards`,
        DELIVERABLES: `/employees/performance/:id?/deliverables`,
        SKILLS: `/employees/performance/:id?/skills`,
        TIMELINE: `/employees/performance/:id?/timeline`,
      },
      PERFORMANCE_TIMELINE: {
        ANY: `/employees/performance-timeline/:id?/:tab?`,
        HISTORY: `/employees/performance-timeline/:id?/history`,
        SKILLS: `/employees/performance-timeline/:id?/skills`,
      },
      // TODO: refactor routes nesting after disabling old layout
      // PERFORMANCE_NEW_LAYOUT is a temp intermediary until the new implementation is ready
      PERFORMANCE_NEW_LAYOUT: {
        ANY: `/employees/performance-tab/:id?/:tab?/:subtab?`,
        SUMMARY: `/employees/performance-tab/:id/summary`,
        PERFORMANCE: {
          ANY: `/employees/performance-tab/:id/performance/:subtab?`,
          SUMMARY: `/employees/performance-tab/:id/performance/summary`,
          UPWARDS: `/employees/performance-tab/:id/performance/upwards`,
          DELIVERABLES: `/employees/performance-tab/:id/performance/deliverables`,
          SKILLS: `/employees/performance-tab/:id/performance/skills`,
          TIMELINE: `/employees/performance-tab/:id/performance/timeline`,
        },
        GOALS: {
          ANY: `/employees/performance-tab/:id/goals/:tab`,
          GENERAL: `/employees/performance-tab/:id/goals/general`,
          TARGETS: `/employees/performance-tab/:id/goals/targets`,
          ROADMAPS: `/employees/performance-tab/:id/goals/roadmaps`,
        },
        KPI: {
          ANY: `/employees/performance-tab/:id/kpi/:subtab?`,
          PERSONAL: `/employees/performance-tab/:id/kpi/personal`,
          RELEVANT: `/employees/performance-tab/:id/kpi/relevant`,
        },
        ROADMAP: `/employees/performance-tab/:id/roadmap`,
        MEETINGS: `/employees/performance-tab/:id/meetings`,
        HIRING: `/employees/performance-tab/:id/hiring`,
        TALENT: {
          ANY: `/employees/performance-tab/:id/talent/:subtab?`,
          DIRECT_REPORTS: `/employees/performance-tab/:id/talent/direct-reports`,
          FUNCTIONAL_REPORTS: `/employees/performance-tab/:id/talent/functional-reports`,
          DIRECT_REPORTS_PERFORMANCE: `/employees/performance-tab/:id/talent/direct-reports-performance`,
          FUNCTIONAL_REPORTS_PERFORMANCE: `/employees/performance-tab/:id/talent/functional-reports-performance`,
          HIERARCHY: `/employees/performance-tab/:id/talent/hierarchy`,
          HIRING: `/employees/performance-tab/:id/talent/hiring`,
        },
        ENGAGEMENT: {
          ANY: `/employees/performance-tab/:id/engagement/:subtab?`,
          CATEGORIES: `/employees/performance-tab/:id/engagement/categories`,
          QUESTIONS: `/employees/performance-tab/:id/engagement/questions`,
          COMMENTS: `/employees/performance-tab/:id/engagement/comments`,
        },
        AUDIT: `/employees/performance-tab/:id/audit`,
        RISK: {
          ANY: `/employees/performance-tab/:id/risk/:subtab?`,
          SUMMARY: `/employees/performance-tab/:id/risk/summary`,
          INDICATORS: `/employees/performance-tab/:id/risk/indicators`,
          CONTROLS: `/employees/performance-tab/:id/risk/controls`,
          INCIDENTS: `/employees/performance-tab/:id/risk/incidents`,
        },
        FINDINGS: `/employees/findings/:id?`,
      },
      UPWARDS: `/employees/performance-upwards/:id?`,
      /** @deprecated */
      KPI: {
        ANY: `/employees/kpi/:id?/:subtab?`,
        PERSONAL: `/employees/kpi/:id?/personal`,
        RELEVANT: `/employees/kpi/:id?/relevant`,
      },
      /** @deprecated */
      ENGAGEMENT: {
        ANY: `/:scope/engagement/:id/:subtab?`,
        CATEGORIES: `/employees/engagement/:id/drivers`,
        QUESTIONS: `/employees/engagement/:id/questions`,
        COMMENTS: `/employees/engagement/:id/comments`,
      },
      CHANGELOG: `/employees/changelog/:id?`,
      ACCESS_REQUESTS: `/employees/access-requests/:id?`,
      DOCUMENTS: `/employees/documents/:id?`,
      DOCUMENTS_BY_CATEGORY: `/employees/documents-by-category/:employeeId/:categoryId`,
      OWNERSHIP: {
        ANY: `/employees/ownership/:id?/:subtab?`,
        ALL: `/employees/ownership/:id?/all`,
        ACCESS: `/employees/ownership/:id?/access`,
        DIRECT_REPORTS: `/employees/ownership/:id?/direct-reports`,
        FUNCTIONAL_REPORTS: `/employees/ownership/:id?/functional-reports`,
        TEAMS: `/employees/ownership/:id?/teams`,
        DEPARTMENTS: `/employees/ownership/:id?/departments`,
        REQUISITIONS_RECRUITER: `/employees/ownership/:id?/requisitions`,
        REQUISITIONS_MANAGER: `/employees/ownership/:id?/requisitions-manager`,
        KEY_PERSON: `/employees/ownership/:id?/key-person`,
        VENDORS: `/employees/ownership/:id?/vendors`,
        DATA: `/employees/ownership/:id?/data`,
        JOB_POSTINGS_RECRUITER: `/employees/ownership/:id?/job-postings-recruiter`,
        CANDIDATES_RECRUITER: `/employees/ownership/:id?/candidates-recruiter`,
        CANDIDATES_HIRING_MANAGER: `/employees/ownership/:id?/candidates-hiring-manager`,
        OFFERS: `/employees/ownership/:id?/offers`,
      },
      LABELS: `/employees/labels/:id?`,
      RISK: {
        ANY: `/employees/risk/:id?/:subtab?`,
        SUMMARY: `/employees/risk/:id?/summary`,
        INDICATORS: `/employees/risk/:id?/indicators`,
        CONTROLS: `/employees/risk/:id?/controls`,
        INCIDENTS: `/employees/risk/:id?/incidents`,
      },
      TIME_OFF: {
        ANY: `/employees/time-off/:id?/:subtab?`,
        REQUESTS: `/employees/time-off/:id?/requests`,
        POLICIES: `/employees/time-off/:id?/policies`,
        BALANCES: `/employees/time-off/:id?/balances`,
        TEAM: `/employees/time-off/:id?/team`,
        TEAM_CALENDAR: `/employees/time-off/:id/team-calendar`,
      },
      COMPENSATION_OVERVIEW: `/employees/compensation-overview/:id`,
      COMPLIANCE_OVERVIEW: `/employees/compliance-overview/:id`,
      ANALYTICS: `/employees/analytics/:id`,
      KEY_PERSON: `/employees/key-person/:id`,
      LINKED_ACCOUNTS: `/employees/linked-accounts/:id`,
      MEETINGS: `/employees/meetings/:id`,
      SCHEDULE: `/employees/schedule/:id?`,
      REFERRALS: `/employees/referrals/:id?`,
      MY_PROFILE: `/employees/myprofile`,
      PREFERENCES: `/employees/preferences/:id?`,
      FEEDBACK: {
        ANY: `/employees/:userId?/feedback/:tab/:id?`,
        ANYTIME: `/employees/:userId?/feedback/anytime/:id?`,
        ONE_TO_ONE: `/employees/:userId?/feedback/oneToOne/:id?`,
      },
    },
    BENCHMARK: {
      ANY: `/benchmark/:tab/:id?`,
      GENERAL: `/benchmark/general/:id?`,
      PREVIEW: `/benchmark/preview/:id?`,
      CHANGELOG: `/benchmark/changelog/:id?`,
    },
    BULK_EDIT_COMPENSATION_BANDS: `/bulk-edit/compensation/bands`,
    BUDGET_POOL: {
      ANY: `/budget-pool/:tab/:id?`,
      GENERAL: `/budget-pool/general/:id?`,
      PREVIEW: `/budget-pool/preview/:id?`,
    },
    COMPANY_COMPENSATION_REVIEW_CYCLE: {
      ANY: `/company-compensation-review-cycle/:tab/:id?`,
      GENERAL: `/company-compensation-review-cycle/general/:id?`,
      PREVIEW: `/company-compensation-review-cycle/preview/:id?`,
    },
    UPDATE_ORGANISATION_STRUCTURE: {
      ANY: `/update-organisation-structure/:type`,
      DEPARTMENT: `/update-organisation-structure/department`,
      TEAM: `/update-organisation-structure/team`,
      ROLE: `/update-organisation-structure/role`,
      ENTITY: `/update-organisation-structure/entity`,
    },
    EDIT_COMPANY_BUDGET: `/edit-company-budget/:id?/:currencyId?`,
    EDIT_DEPARTMENT_BUDGET: `/edit-department-budget/:id?/:currencyCode?`,
    VIEW_CUSTOM_BUDGET_POOL: `/view-budget-pool/:id`,
    OFFBOARDING: {
      ANY: `/offboarding/:tab/:employeeId`,
      DASHBOARD: `/offboarding/dashboard/:employeeId`,
      PAYMENTS: `/offboarding/payments/:employeeId`,
      ACCESS_RETENTION: `/offboarding/access-retention/:employeeId`,
      SEND_EMAIL: `/offboarding/send-email/:employeeId`,
      TICKETS: `/offboarding/tickets/:employeeId`,
    },
    TIME_OFF_REGIMES: {
      ANY: `/timeoff-regimes/:tab/:id?`,
      GENERAL: `/timeoff-regimes/general/:id?`,
      PREVIEW: `/timeoff-regimes/preview/:id?`,
    },
    TIME_OFF_CATEGORIES: {
      ANY: `/timeoff-categories/:tab/:id?`,
      GENERAL: `/timeoff-categories/general/:id?`,
    },
    TIME_OFF_POLICY: {
      ANY: `/timeoff-policy/:tab/:subtab/:id?`,
      PREVIEW: `/timeoff-policy/preview/all/:id`,
      EDIT: {
        ANY: `/timeoff-policy/edit/:subtab/:id?`,
        BASICS: `/timeoff-policy/edit/basics/:id?`,
        DETAILS: `/timeoff-policy/edit/details/:id?`,
        REQUESTS: `/timeoff-policy/edit/requests/:id?`,
        ELIGIBILITY: `/timeoff-policy/edit/eligibility/:id?`,
        REVIEW: `/timeoff-policy/edit/review/:id?`,
      },
    },
    TIME_OFF_BALANCE: {
      ANY: `/timeoff-balance/:tab/:id?`,
      PREVIEW: `/timeoff-balance/preview/:id?`,
    },
    SCHEDULING_POLICY: {
      ANY: `/scheduling-policy/:tab/:id?`,
      PREVIEW: `/scheduling-policy/preview/:id?`,
      GENERAL: `/scheduling-policy/general/:id?`,
      SHIFTS: `/scheduling-policy/shifts/:id?`,
    },
    SCHEDULING_POLICY_SHIFT: {
      ANY: `/scheduling-policy-shift/:tab/:policyId/:id?`,
      GENERAL: `/scheduling-policy-shift/general/:policyId/:id?`,
      PREVIEW: `/scheduling-policy-shift/preview/:policyId/:id?`,
    },
    SCHEDULE_SHIFT: {
      ANY: `/schedule-shift/:tab/:employeeId/:id?`,
      GENERAL: `/schedule-shift/general/:employeeId/:id?`,
      PREVIEW: `/schedule-shift/preview/:employeeId/:id?`,
      CHANGELOG: `/schedule-shift/changelog/:employeeId/:id?`,
    },
    DATA_RETENTION_SETTINGS: {
      ANY: `/data-retention-settings/:tab/:id?`,
      GENERAL: `/data-retention-settings/general/:id?`,
      PREVIEW: `/data-retention-settings/preview/:id?`,
    },
    FAQ_CATEGORY: {
      ANY: `/faq-category/:id?`,
      GENERAL: `/faq-category/:id?`,
    },
    DEFAULT_WORKING_HOURS: {
      ANY: `/default-working-hours/:tab/:id/:new?`,
      GENERAL: `/default-working-hours/general/:id/:new?`,
    },
    BENEFITS: {
      ANY: `/benefits/:tab/:id?/:packageId?/:packageTab?`,
      GENERAL: `/benefits/general/:id?`,
      PREVIEW: `/benefits/preview/:id?`,
      PACKAGE_PREVIEW: `/benefits/package/:id?/:packageId?/preview`,
      PACKAGE_EDIT: `/benefits/package/:id?/:packageId?/edit`,
      PACKAGE_NEW: `/benefits/package-new/:id?`,
    },
    EMPLOYEE_BENEFIT: {
      ANY: `/employee-benefit/:tab/:employeeId/:id/:packageId?`,
      SUMMARY: `/employee-benefit/summary/:employeeId/:id`,
      PACKAGE_SELECT: `/employee-benefit/package-select/:employeeId/:id`,
      PACKAGE_DETAILS: `/employee-benefit/package-details/:employeeId/:id/:packageId`,
      PREVIEW: `/employee-benefit/preview/:employeeId/:id`,
      EDIT: `/employee-benefit/edit/:employeeId/:id`,
      CHANGELOG: `/employee-benefit/changelog/:employeeId/:id`,
    },
    EMPLOYEE_BENEFIT_DEPENDANTS: {
      ANY: `/employee-benefit-dependants/:tab/:employeeId/:id/:dependantId?/:dependantTab?`,
      PREVIEW: `/employee-benefit-dependants/dependant/:employeeId/:id/:dependantId/preview`,
      EDIT: `/employee-benefit-dependants/dependant/:employeeId/:id/:dependantId/edit`,
      NEW: `/employee-benefit-dependants/new/:employeeId/:id`,
    },
    EMAIL_TEMPLATE: {
      ANY: `/email-templates/:tab/:id?`,
      GENERAL: `/email-templates/general/:id?`,
      PREVIEW: `/email-templates/preview/:id?`,
    },
    EMPLOYEE_EMAIL_TEMPLATE: {
      ANY: `/employee-email-templates/:tab/:id?`,
      GENERAL: `/employee-email-templates/general/:id?`,
      PREVIEW: `/employee-email-templates/preview/:id?`,
    },
    EMPLOYEE_ONBOARDING_TEMPLATE: {
      ANY: `/employee-onboarding-templates/:tab/:id?`,
      GENERAL: `/employee-onboarding-templates/general/:id?`,
      PREVIEW: `/employee-onboarding-templates/preview/:id?`,
    },
    EMPLOYEE_CHANGE_REQUEST: {
      ANY: `/employee-change-request/:type/:employeeId/:id?`,
      NEW: `/employee-change-request/:type/:employeeId`,
      DETAILS: `/employee-change-request/details/:employeeId/:id`,
    },
    EMPLOYEE_PERFORMANCE: {
      ANY: `/employee-performance/:tab/:employeeId?/:id?`,
      GENERAL: `/employee-performance/general/:employeeId?/:id?`,
      SKILLS: `/employee-performance/skills/:employeeId?/:id?`,
      DELIVERABLES: `/employee-performance/deliverables/:employeeId?/:id?`,
      CULTURE_FIT: `/employee-performance/culture-fit/:employeeId?/:id?`,
      RECOMMENDATION: `/employee-performance/recommendation/:employeeId?/:id?`,
      SUMMARY: `/employee-performance/summary/:employeeId?/:id?`,
    },
    EMPLOYEE_PERFORMANCE_LAYOUT: `/employee-performance/:employeeId?/:id?`,
    EMPLOYEE_PERFORMANCE_VIEW_LAYOUT: `/employee-performance/:employeeId?/:id?/view`,
    EMPLOYEE_RESIGNATION: {
      ANY: `/employee-resignation/:tab/:action/:employeeId/:id?`,
      REASONS: `/employee-resignation/reasons/edit/:employeeId/:id?`,
      REASONS_PREVIEW: `/employee-resignation/reasons/view/:employeeId/:id?`,
      SUBMIT: `/employee-resignation/submit/edit/:employeeId/:id?`,
      SUBMIT_PREVIEW: `/employee-resignation/submit/view/:employeeId/:id?`,
      LETTER: `/employee-resignation/letter/edit/:employeeId/:id?`,
      LETTER_EDIT_PREVIEW: `/employee-resignation/letter/edit-view/:employeeId/:id?`,
      LETTER_PREVIEW: `/employee-resignation/letter/view/:employeeId/:id?`,
      DASHBOARD: `/employee-resignation/dashboard/view/:employeeId/:id?`,
      DASHBOARD_LETTER_APPROVE: `/employee-resignation/dashboard/approve/:employeeId/:id?`,
    },
    ONBOARDING_TIMELINE: {
      ANY: `/onboarding-timeline/:tab/:employeeId/:id`,
      START: `/onboarding-timeline/start/:employeeId/:id`,
      WORK: `/onboarding-timeline/work/:employeeId/:id`,
      DOCUMENTS: `/onboarding-timeline/documents/:employeeId/:id`,
      CONTRACT: `/onboarding-timeline/contract/:employeeId/:id`,
      SCREENING: `/onboarding-timeline/screening/:employeeId/:id`,
      RIGHT_TO_WORK: `/onboarding-timeline/right-to-work/:employeeId/:id`,
      FINISH: `/onboarding-timeline/finish/:employeeId/:id`,
    },
    ONBOARDING_TIMELINE_V2: {
      ANY: `/onboarding-timeline-v2/:tab/:id/:onboardingId/:taskId?`,
      OVERVIEW: `/onboarding-timeline-v2/overview/:id/:onboardingId`,
      EMAILS: `/onboarding-timeline-v2/emails/:id/:onboardingId`,
      COMMENTS: `/onboarding-timeline-v2/comments/:id/:onboardingId`,
      TASK_VIEW: `/onboarding-timeline-v2/tasks/:id/:onboardingId/:taskId?`,
    },
    ONBOARDING_SEND_EMAIL: {
      ANY: `/onboarding-send-email/:tab/:employeeId/:processId/:action`,
      GENERAL: `/onboarding-send-email/general/:employeeId/:processId/:action`,
    },
    ONBOARDING_SEND_EMAIL_V2: {
      ANY: `/onboarding-send-email-v2/:tab/:employeeId/:threadId?/:emailId?`,
      GENERAL: `/onboarding-send-email-v2/general/:employeeId/:threadId?/:emailId?`,
      VIEW: `/onboarding-send-email-v2/view/:employeeId/:threadId/:emailId`,
    },
    UPWARDS_REVIEW: {
      ANY: `/upwards-review/:tab/:employeeId?/:id?`,
      SKILLS: `/upwards-review/skills/:employeeId?/:id?`,
      CULTURE_FIT: `/upwards-review/culture-fit/:employeeId?/:id?`,
      SUMMARY: `/upwards-review/summary/:employeeId?/:id?`,
    },
    UPWARDS_REVIEW_LAYOUT: `/upwards-review/:employeeId/:id`,
    UPWARDS_REVIEW_VIEW_LAYOUT: `/upwards-review/:employeeId/:id/view`,
    UPWARDS_REVIEW_VIEW: {
      ANY: `/upwards-review/:tab/:employeeId?/:id?/view`,
      SKILLS: `/upwards-review/skills/:employeeId?/:id?/view`,
      CULTURE_FIT: `/upwards-review/culture-fit/:employeeId?/:id?/view`,
      SUMMARY: `/upwards-review/summary/:employeeId?/:id?/view`,
    },
    EMPLOYEE_PERFORMANCE_VIEW: {
      ANY: `/employee-performance/:tab/:employeeId?/:id?/view`,
      GENERAL: `/employee-performance/general/:employeeId?/:id?/view`,
      SKILLS: `/employee-performance/skills/:employeeId?/:id?/view`,
      DELIVERABLES: `/employee-performance/deliverables/:employeeId?/:id?/view`,
      CULTURE_FIT: `/employee-performance/culture-fit/:employeeId?/:id?/view`,
      RECOMMENDATION: `/employee-performance/recommendation/:employeeId?/:id?/view`,
      SUMMARY: `/employee-performance/summary/:employeeId?/:id?/view`,
    },
    PROBATION_REVIEW: {
      ANY: `/probation-review/:tab/:employeeId?/:id?`,
      DELIVERABLES: `/probation-review/deliverables/:employeeId?/:id?`,
      SKILLS: `/probation-review/skills/:employeeId?/:id?`,
      CULTURE_FIT: `/probation-review/culture-fit/:employeeId?/:id?`,
      SUMMARY: `/probation-review/summary/:employeeId?/:id?`,
    },
    PROBATION_REVIEW_LAYOUT: `/probation-review/:employeeId?/:id?`,
    PROBATION_OVERVIEW: {
      ANY: `/probation-overview/:employeeId?/:cycleId?/:tab?/:id?`,
      PROBATION_GOALS: `/probation-overview/:employeeId?/:cycleId?/probation-goals`,
      CHECKPOINT: `/probation-overview/:employeeId?/:cycleId?/checkpoint/:id?`,
      RECOMMENDATIONS: `/probation-overview/:employeeId?/:cycleId?/recommendations`,
      COMMITTEE: `/probation-overview/:employeeId?/:cycleId?/committee`,
      CYCLE_SETTINGS: `/probation-overview/:employeeId?/:cycleId?/cycle-settings`,
    },
    PROBATION_GOALS: `/probation-goals/:employeeId?/:cycleId?`,
    PROBATION_REVIEW_VIEW: {
      ANY: `/probation-review/:tab/:employeeId?/:id?/view`,
      DELIVERABLES: `/probation-review/deliverables/:employeeId?/:id?/view`,
      SKILLS: `/probation-review/skills/:employeeId?/:id?/view`,
      CULTURE_FIT: `/probation-review/culture-fit/:employeeId?/:id?/view`,
      SUMMARY: `/probation-review/summary/:employeeId?/:id?/view`,
    },
    PROBATION_REVIEW_VIEW_LAYOUT: `/probation-review/:employeeId?/:id?/view`,
    PROBATION_FINAL_RESULT: {
      ANY: `/probation-result/:tab/:employeeId/:cycleId/:id?`,
      GENERAL: `/probation-result/general/:employeeId/:cycleId/:id?`,
    },
    PROBATION_COMMITTEE_RESULT: {
      ANY: `/committee-result/:tab/:employeeId/:cycleId/:id?`,
      GENERAL: `/committee-result/general/:employeeId/:cycleId/:id?`,
    },
    PIP: {
      ANY: `/pip/:employeeId?/:subtab?/:id?`,
      GOAL_SETTINGS: `/pip/:employeeId?/goal-settings/:id?`,
      SETTINGS: `/pip/:employeeId?/settings/:id?`,
    },
    PIP_OVERVIEW: {
      ANY: `/pip-overview/:employeeId?/:cycleId?/:tab?/:id?`,
      PIP_GOALS: `/pip-overview/:employeeId?/:cycleId?/pip-goals`,
      CHECKPOINT: `/pip-overview/:employeeId?/:cycleId?/checkpoint/:id?`,
      COMMITTEE: `/pip-overview/:employeeId?/:cycleId?/committee`,
      SETTINGS: `/pip-overview/:employeeId?/:cycleId?/settings`,
      CYCLE_SETTINGS: `/pip-overview/:employeeId?/:cycleId?/cycle-settings`,
    },
    PIP_REVIEW: {
      ANY: `/pip-review/:tab/:employeeId?/:id?`,
      CULTURE_FIT: `/pip-review/culture-fit/:employeeId?/:id?`,
      DELIVERABLES: `/pip-review/deliverables/:employeeId?/:id?`,
      SKILLS: `/pip-review/skills/:employeeId?/:id?`,
      SUMMARY: `/pip-review/summary/:employeeId?/:id?`,
    },
    PIP_REVIEW_LAYOUT: `/pip-review/:employeeId?/:id?`,
    PIP_REVIEW_VIEW: {
      ANY: `/pip-review/:tab/:employeeId?/:id?/view`,
      CULTURE_FIT: `/pip-review/culture-fit/:employeeId?/:id?/view`,
      DELIVERABLES: `/pip-review/deliverables/:employeeId?/:id?/view`,
      SKILLS: `/pip-review/skills/:employeeId?/:id?/view`,
      SUMMARY: `/pip-review/summary/:employeeId?/:id?/view`,
    },
    PIP_REVIEW_VIEW_LAYOUT: `/pip-review/:employeeId/:id/view`,
    PIP_COMMITTEE_RESULT: {
      ANY: `/pip-committee-result/:tab/:employeeId/:cycleId/:id?`,
      GENERAL: `/pip-committee-result/general/:employeeId/:cycleId/:id?`,
    },
    COMPANY: {
      ANY: `/company/:tab/:subtab?`,
      GOALS: {
        ANY: `/company/goals/:tab?`,
        GENERAL: '/company/goals/general',
        TARGETS: '/company/goals/targets',
        ROADMAPS: '/company/goals/roadmaps',
      },
      KPI: {
        ANY: `/company/kpi/:subtab?`,
        GOALS: `/company/kpi/goals`,
        COMPANY: `/company/kpi/company`,
        DEPARTMENT: `/company/kpi/department`,
      },
      ANALYTICS_DASHBOARDS: `/company/analytics-dashboards`,
      ROADMAP: `/company/roadmap`,
      FINDINGS: `/company/findings`,
      COMPENSATION: `/company/compensation`,
      BUDGET: {
        ANY: `/company/budget/:subtab`,
        SPEND: `/company/budget/spend`,
      },
      RISK: {
        ANY: `/company/risk/:subtab?`,
        SUMMARY: `/company/risk/summary`,
        INDICATORS: `/company/risk/indicators`,
        CONTROLS: `/company/risk/controls`,
        INCIDENTS: `/company/risk/incidents`,
      },
      SPENDS: `/company/spends`,
      ENGAGEMENT: {
        ANY: `/company/engagement/:subtab?`,
        CATEGORIES: `/company/engagement/categories`,
        QUESTIONS: `/company/engagement/questions`,
        COMMENTS: `/company/engagement/comments`,
      },
      TALENT: {
        ANY: `/company/talent/:subtab?`,
        PERFORMANCE: `/company/talent/performance`,
      },
      VALUES: {
        ANY: `/company/values/:subtab?`,
        CULTURAL_SKILLS: `/company/values/cultural-skills`,
        COMPETENCY_MATRIX: `/company/values/competency-matrix`,
      },
    },
    ROADMAP: {
      ANY: '/roadmap/:tab/:id?',
      SELECT_LEVEL: '/roadmap/create',
      NEW_JIRA: '/roadmap/new-jira',
      NEW: '/roadmap/edit',
      GENERAL: '/roadmap/edit/:id',
    },
    REQUISITION: {
      ANY: `/requisition/:tab/:id?`,
      ROLE: `/requisition/role/:id?`,
      CALIBRATION: `/requisition/calibration/:id?`,
      CANDIDATES: `/requisition/candidates/:id?`,
      SETTINGS: `/requisition/settings/:id?`,
      INTERVIEW_PROCESS: `/requisition/interview-process/:id?`,
      HIRES: `/requisition/hires/:id?`,
      CHANGELOG: `/requisition/changelog/:id?`,
    },
    TEAM: {
      ANY: `/team/:tab/:id?/:subtab?`,
      SUMMARY: `/team/summary/:id?`,
      /** @deprecated */
      GENERAL: `/team/general/:id?`,
      SETTINGS: `/team/settings/:id?`,
      BUDGETS: `/team/budgets/:id?`,
      ACCESS_REQUESTS: `/team/data-access/:id`,
      REQUISITIONS: `/team/requisitions/:id?`,
      MEETINGS: `/team/meetings/:id?`,
      ROADMAP: `/team/roadmap/:id?`,
      KPI: `/team/kpi/:id?`,
      GOALS: {
        ANY: `/team/goals/:id/:subtub`,
        GENERAL: `/team/goals/:id/general`,
        TARGETS: `/team/goals/:id/targets`,
        ROADMAPS: `/team/goals/:id/roadmaps`,
      },
      KPI_WEIGHTS: `/team/kpi-weights/:id?`,
      RISK: {
        ANY: `/team/risk/:id/:subtab?`,
        SUMMARY: `/team/risk/:id/summary`,
        INDICATORS: `/team/risk/:id/indicators`,
        CONTROLS: `/team/risk/:id/controls`,
        INCIDENTS: `/team/risk/:id/incidents`,
      },
      AUDIT: `/team/audit/:id?`,
      TALENT: {
        ANY: `/team/talent/:id/:subtab?`,
        COMPENSATION: `/team/talent/:id/compensation`,
        GENERAL: `/team/talent/:id/general`,
        PERFORMANCE: `/team/talent/:id/performance`,
        TIME_OFF: `/team/talent/:id/time-off`,
        SCHEDULE: `/team/talent/:id/schedule`,
      },
      ENGAGEMENT: {
        ANY: `/team/engagement/:id/:subtab?`,
        CATEGORIES: `/team/engagement/:id/categories`,
        QUESTIONS: `/team/engagement/:id/questions`,
        COMMENTS: `/team/engagement/:id/comments`,
      },
      CX: {
        ANY: `/team/cx/:id/:subtab?`,
        ALL: `/team/cx/:id/`,
        COMPLAINTS: `/team/cx/:id/complaints`,
        CSAT_DETRACTORS: `/team/cx/:id/csat-detractors`,
        SUPPORT_LOAD: `/team/cx/:id/support-load`,
        TRUSTPILOT: `/team/cx/:id/trustpilot`,
        NPS: `/team/cx/:id/nps`,
      },
      BUGS: `/team/bugs/:id`,
      BUGS_V2: `/team/bugs-new/:id`,
      BUDGET: `/team/budget/:id`,
      ISSUES: {
        ANY: `/team/issues/:id/:subtab`,
        FINDING: `/team/issues/:id/user`, // yes, it's actually findings
        PRODUCT: `/team/issues/:id/product`,
        TECHNICAL: `/team/issues/:id/technical`,
        USER: `/team/issues/:id/issue`,
      },
      CX_BUGS: `/team/cx-bugs/:id`,
      CX_BUGS_V2: `/team/cx-bugs-new/:id`,
      ANALYTICS_DASHBOARDS: `/team/analytics-dashboards/:id`,
    },
    DEPARTMENT: {
      ANY: `/department/:tab/:id?/:subtab?`,
      SUMMARY: `/department/summary/:id`,
      LEADERSHIP: `/department/leadership/:id?`,
      AUDIT: `/department/audit/:id?`,
      RISK: {
        ANY: `/department/risk/:id/:subtab?`,
        SUMMARY: `/department/risk/:id/summary`,
        CONTROLS: `/department/risk/:id/controls`,
        INDICATORS: `/department/risk/:id/indicators`,
        INCIDENTS: `/department/risk/:id/incidents`,
        KARMA: `/department/risk/:id/karma`,
      },
      CX: {
        ANY: `/department/cx/:id/:subtab?`,
        ALL: `/department/cx/:id`,
        COMPLAINTS: `/department/cx/:id/complaints`,
        CSAT_DETRACTORS: `/department/cx/:id/csat-detractors`,
        SUPPORT_LOAD: `/department/cx/:id/support-load`,
        TRUSTPILOT: `/department/cx/:id/trustpilot`,
        NPS: `/department/cx/:id/nps`,
      },
      BUGS: `/department/bugs/:id`,
      BUGS_V2: `/department/bugs-new/:id`,
      CX_BUGS: `/department/cx-bugs/:id`,
      CX_BUGS_V2: `/department/cx-bugs-new/:id`,
      ENGAGEMENT: {
        ANY: `/department/engagement/:id/:subtab?`,
        CATEGORIES: `/department/engagement/:id/categories`,
        QUESTIONS: `/department/engagement/:id/questions`,
        COMMENTS: `/department/engagement/:id/comments`,
      },
      TALENT: {
        ANY: `/department/talent/:id/:subtab?`,
        GENERAL: `/department/talent/:id/general`,
        PERFORMANCE: `/department/talent/:id/performance`,
        COMPENSATION: `/department/talent/:id/compensation`,
        PROMOTION_NOMINATIONS: `/department/talent/:id/promotion-nominations`,
        SCHEDULE: `/department/talent/:id/schedule`,
        TIME_OFF: `/department/talent/:id/time-off`,
      },
      ANALYTICS_DASHBOARDS: `/department/analytics-dashboards/:id`,
      ISSUES: {
        ANY: `/department/issues/:id/:subtab`,
        FINDING: `/department/issues/:id/user`,
        PRODUCT: `/department/issues/:id/product`,
        TECHNICAL: `/department/issues/:id/technical`,
        USER: `/department/issues/:id/issue`,
      },
      /** @deprecated */
      GENERAL: `/department/general/:id?`,
      SETTINGS: `/department/settings/:id?`,
      REQUISITIONS: `/department/requisitions/:id?`,
      MEETINGS: `/department/meetings/:id?`,
      STRUCTURE: `/department/structure/:id?`,
      ROADMAP: `/department/roadmap/:id?`,
      KPI: `/department/kpi/:id?`,
      GOALS: {
        ANY: `/department/goals/:id/:subtub?`,
        GENERAL: `/department/goals/:id/general`,
        TARGETS: '/department/goals/:id/targets',
        ROADMAPS: `/department/goals/:id/roadmaps`,
      },
      KARMA: `/department/karma/:id?`,
      BUDGET: {
        ANY: `/department/budget/:id/:subtab`,
        SPENT: `/department/budget/:id/spent`,
        DISTRIBUTION: `/department/budget/:id/distribution`,
      },
    },
    NOTIFICATIONS: {
      ANY: `/notifications/:tab?/:subtab?`,
      GENERAL: {
        ANY: `/notifications/general/:subtab?`,
        ALL: `/notifications/general/all`,
        REVIEWS: `/notifications/general/reviews`,
        UPWARDS_REVIEW: `/notifications/general/upwards-reviews`,
        PROBATION: `/notifications/general/probation`,
        KPIS: `/notifications/general/kpis`,
        REQUISITIONS: `/notifications/general/requisitions`,
        PENDING_DOCUMENTS: `/notifications/general/pending-documents`,
        ROLES: `/notifications/general/roles`,
        TRAININGS: `/notifications/general/trainings`,
        REVIEW_CALIBRATION: `/notifications/general/review-calibration`,
        DATA_ACCESS: `/notifications/general/data-access`,
        SERVICE_DESK: `/notifications/general/service-desk`,
        FINDINGS: `/notifications/general/findings`,
        SKILLS: `/notifications/general/skills`,
        DELIVERABLES: `/notifications/general/deliverables`,
        OTHER: `/notifications/general/other`,
      },
    },
    SPECIALISATIONS: {
      ANY: `/specialisation/:tab/:id?/:subtab?`,
      GENERAL: `/specialisation/general/:id?`,
      PREVIEW: `/specialisation/preview/:id?/:subtab?`,
      COMPETENCY_MATRIX: `/specialisation/competency-matrix/:id?`,
      POSTINGS: `/specialisation/postings/:id?`,
      CANDIDATE_EXPECTATIONS: `/specialisation/candidate-expectations/:id?`,
      HIRING_PROCESS: `/specialisation/hiring-process/:id?/:subtab?`,
      CANDIDATES: `/specialisation/candidates/:id?`,
      ANALYTICS_DASHBOARDS: `/specialisation/analytics-dashboards/:id`,
      SCORECARDS: {
        ANY: `/specialisation/scorecards/:id?/:subtab?`,
        CV_REQUIREMENTS: `/specialisation/scorecards/:id?/cv-requirements`,
        SCREEN_CALL: `/specialisation/scorecards/:id?/screen-call`,
        PROBLEM_SOLVING: `/specialisation/scorecards/:id?/problem-solving`,
        HOME_TASK: `/specialisation/scorecards/:id?/home-task`,
        HIRING_MANAGEMENT: `/specialisation/scorecards/:id?/hiring-management`,
        PROCESS_BUILDING: `/specialisation/scorecards/:id?/process-building`,
        PROJECT_MANAGEMENT: `/specialisation/scorecards/:id?/project-management`,
        BAR_RAISER: `/specialisation/scorecards/:id?/bar-raiser`,
        HIRING_PANEL: `/specialisation/scorecards/:id?/hiring-panel`,
        REFERENCE_CHECK: `/specialisation/scorecards/:id?/reference-check`,
        SKILL_INTERVIEW: `/specialisation/scorecards/:id?/skill-interview`,
      },

      // @TODO
      SKILL: `/specialisation/skill/:id?`,
      INTERVIEW: `/specialisation/interview/:id?`,
      RECRUITMENT: `/specialisation/recruitment/:id?`,
      REQUISITIONS: `/specialisation/requisitions/:id?`,
      TALENT: {
        ANY: `/specialisation/talent/:id?/:subtab?`,
        GENERAL: `/specialisation/talent/:id/general`,
        PERFORMANCE: `/specialisation/talent/:id/performance`,
      },
      SPECIALISATIONS: `/specialisation/specialisations/:id?`,
      KPI: `/specialisation/kpi/:id?`,
      ENGAGEMENT: {
        ANY: `/specialisation/engagement/:id/:subtab?`,
        CATEGORIES: `/specialisation/engagement/:id/categories`,
        QUESTIONS: `/specialisation/engagement/:id/questions`,
        COMMENTS: `/specialisation/engagement/:id/comments`,
      },
    },
    ROLE: {
      ANY: `/role/:tab/:id?/:subtab?`,
      GENERAL: `/role/general/:id?`,
      PREVIEW: `/role/preview/:id?`,
      SKILL: `/role/skill/:id?`,
      INTERVIEW: `/role/interview/:id?`,
      RECRUITMENT: `/role/recruitment/:id?`,
      REQUISITIONS: `/role/requisitions/:id?`,
      ANALYTICS_DASHBOARDS: `/role/analytics-dashboards/:id`,
      TALENT: {
        ANY: `/role/talent/:id?/:subtab?`,
        GENERAL: `/role/talent/:id/general`,
        PERFORMANCE: `/role/talent/:id/performance`,
      },
      COMPETENCY_MATRIX: `/role/competency-matrix/:id?`,
      CANDIDATE_EXPECTATIONS: `/role/candidate-expectations/:id?`,
      SPECIALISATIONS: `/role/specialisations/:id?`,
      HIRING_PROCESS: `/role/hiring-process/:id?`,
      KPI: `/role/kpi/:id?`,
      SCORECARDS: {
        ANY: `/role/scorecards/:id?/:subtab?`,
        CV_REQUIREMENTS: `/role/scorecards/:id?/cv-requirements`,
        SCREEN_CALL: `/role/scorecards/:id?/screen-call`,
        PROBLEM_SOLVING: `/role/scorecards/:id?/problem-solving`,
        HOME_TASK: `/role/scorecards/:id?/home-task`,
        HIRING_MANAGEMENT: `/role/scorecards/:id?/hiring-management`,
        PROCESS_BUILDING: `/role/scorecards/:id?/process-building`,
        PROJECT_MANAGEMENT: `/role/scorecards/:id?/project-management`,
        BAR_RAISER: `/role/scorecards/:id?/bar-raiser`,
        HIRING_PANEL: `/role/scorecards/:id?/hiring-panel`,
        REFERENCE_CHECK: `/role/scorecards/:id?/reference-check`,
        SKILL_INTERVIEW: `/role/scorecards/:id?/skill-interview`,
      },
      ENGAGEMENT: {
        ANY: `/role/engagement/:id/:subtab?`,
        CATEGORIES: `/role/engagement/:id/categories`,
        QUESTIONS: `/role/engagement/:id/questions`,
        COMMENTS: `/role/engagement/:id/comments`,
      },
    },
    LABEL: {
      ANY: `/label/:tab/:employeeId/:id?`,
      GENERAL: `/label/general/:employeeId/:id?`,
    },
    LOCATION: {
      ANY: `/location/:tab/:id?`,
      GENERAL: `/location/general/:id?`,
    },
    BANNER: {
      ANY: `/banner/:tab/:id?`,
      GENERAL: `/banner/general/:id?`,
    },
    DOCUMENT: {
      ANY: `/document/:tab/:employeeId?/:id?`,
      GENERAL: `/document/general/:employeeId?/:id?`,
    },
    DOCUMENT_EMBEDDED: {
      ANY: `/document-embedded/:tab/:employeeId/:id?`,
      GENERAL: `/document-embedded/general/:employeeId/:id?`,
    },
    DOCUMENT_DOCUSIGN: {
      ANY: `/document-docusign/:tab/:employeeId/:id?`,
      GENERAL: `/document-docusign/general/:employeeId/:id?`,
    },
    DOCUMENT_REQUEST: {
      ANY: `/document-request/:tab/:employeeId?/:id?`,
      GENERAL: `/document-request/general/:employeeId?/:id?`,
    },
    DOCUMENT_GENERATE: {
      ANY: `/document-generate/:tab/:employeeId?/:id?`,
      GENERAL: `/document-generate/general/:employeeId?/:id?`,
    },
    DOCUMENT_UPLOAD: {
      ANY: `/document-upload/:tab/:employeeId/:id?`,
      GENERAL: `/document-upload/general/:employeeId/:id?`,
    },
    DOCUMENT_TEMPLATE: {
      ANY: `/documents-template/:tab/:id?`,
      GENERAL: `/documents-template/general/:id?`,
      PREVIEW: `/documents-template/preview/:id`,
      PREVIEW_IN_EDITOR: `/documents-template/preview-in-editor/:id`,
    },
    DOCUMENT_COLLECTION: {
      ANY: `/documents-collection/:tab/:id?`,
      GENERAL: `/documents-collection/general/:id?`,
      PREVIEW: `/documents-collection/preview/:id?`,
    },
    DOCUMENT_RULE: {
      ANY: `/documents-rule/:tab/:id?`,
      GENERAL: `/documents-rule/general/:id?`,
      DOCUMENTS: `/documents-rule/documents/:id?`,
      PREVIEW: `/documents-rule/preview/:id?`,
    },
    DOCUMENT_CATEGORIES: {
      ANY: `/documents-categories/:tab/:id?`,
      GENERAL: `/documents-categories/general/:id?`,
    },
    DYNAMIC_GROUPS: {
      ANY: `/dynamic-groups/:tab/:id?`,
      GENERAL: `/dynamic-groups/general/:id?`,
      GROUPS: `/dynamic-groups/groups`,
    },
    CUSTOM_FIELDS: {
      ANY: `/custom-fields/:tab/:id?`,
      GENERAL: `/custom-fields/general/:id?`,
    },
    NOTIFICATION_TEMPLATE: {
      ANY: `/notification-template/:tab/:id?`,
      GENERAL: `/notification-template/general/:id?`,
      PREVIEW: `/notification-template/preview/:id?`,
      LOGS: `/notification-template/logs/:id?`,
    },
    EMPLOYEE_CONTRACT: {
      ANY: `/contract/:tab/:employeeId/:id?`,
      GENERAL: `/contract/general/:employeeId/:id?`,
      PREVIEW: `/contract/preview/:employeeId/:id?`,
      CHANGELOG: `/contract/changelog/:employeeId/:id?`,
    },
    PROBATION_EXTRA_SETTINGS: {
      ANY: `/probation-settings/:tab/:employeeId/:id?`,
      GENERAL: `/probation-settings/general/:employeeId/:id?`,
    },
    EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT: {
      ANY: `/time-off-policy-assignment/:tab/:employeeId/:id?`,
      PREVIEW: `/time-off-policy-assignment/preview/:employeeId/:id?`,
      GENERAL: `/time-off-policy-assignment/general/:employeeId/:id?`,
    },
    EMPLOYEE_TIME_OFF_POLICY_UNASSIGNMENT: {
      ANY: `/time-off-policy-unassignment/:tab/:employeeId/:id?`,
      GENERAL: `/time-off-policy-unassignment/general/:employeeId/:id?`,
    },
    EMPLOYEE_TIME_OFF_REQUEST: {
      ANY: `/time-off-request/:tab/:employeeId/:id?`,
      GENERAL: `/time-off-request/general/:employeeId/:id?`,
      PREVIEW: `/time-off-request/preview/:employeeId/:id?`,
      CHANGELOG: `/time-off-request/changelog/:employeeId/:id?`,
    },
    EMPLOYEE_TIME_OFF_ABSENCE: {
      ANY: `/time-off-absence/:tab/:employeeId/:id?`,
      GENERAL: `/time-off-absence/general/:employeeId/:id?`,
    },
    EMPLOYEE_RIGHT_TO_WORK: {
      ANY: `/right-to-work/:tab/:employeeId/:id?`,
      GENERAL: `/right-to-work/general/:employeeId/:id?`,
    },
    ENGAGEMENT_QUESTION: {
      ANY: `/engagement-question/:tab/:id?`,
      GENERAL: `/engagement-question/general/:id?`,
    },
    SCREENING: {
      ANY: `/screening/:screeningType/:tab/:employeeId?/:id?`,
      SELECT: `/screening/select/type/:employeeId?`,
      MANUAL: {
        ANY: `/screening/manual/:tab/:employeeId?/:id?`,
        GENERAL: `/screening/manual/general/:employeeId?/:id?`,
        PREVIEW: `/screening/manual/preview/:employeeId/:id`,
      },
      HIRERIGHT: {
        ANY: `/screening/hireright/:tab/:employeeId?/:id?`,
        GENERAL: `/screening/hireright/general/:employeeId?`,
        PREVIEW: `/screening/hireright/preview/:employeeId/:id`,
      },
    },
    KEY_PERSON: {
      ANY: `/key-person/:tab/:employeeId/:id?`,
      GENERAL: `/key-person/general/:employeeId/:id?`,
      PREVIEW: `/key-person/preview/:employeeId/:id?`,
      CHANGELOG: `/key-person/changelog/:employeeId/:id?`,
    },
    LINKED_ACCOUNT: {
      ANY: `/linked-account/:tab/:employeeId/:id?`,
      GENERAL: `/linked-account/general/:employeeId/:id?`,
      PREVIEW: `/linked-account/preview/:employeeId/:id`,
    },
    EMPLOYEE_SALARY_SACRIFICE: {
      ANY: `/salary-sacrifice/:tab/:employeeId/:id?`,
      GENERAL: `/salary-sacrifice/general/:employeeId/:id?`,
    },
    WHITELIST: {
      ANY: `/whitelist/:tab/:id?`,
      GENERAL: `/whitelist/general/:id?`,
    },
    INTERVIEW_SCORECARD_TEMPLATE: {
      ANY: `/interview-scorecard-template/:tab/:id?`,
      SETTINGS: `/interview-scorecard-template/settings/:id?`,
      PREVIEW: `/interview-scorecard-template/preview/:id?`,
    },
    FUNCTION: {
      ANY: `/function/:tab/:id?/:subtab?`,
      SUMMARY: `/function/summary/:id?`,
      KPI: `/function/kpi/:id?`,
      SETTINGS: `/function/settings/:id?`,
      ROLES: `/function/roles/:id?`,
      SPECIALISATIONS: `/function/specialisations/:id?`,
      SKILLS: `/function/skills/:id?`,
      HIERARCHY: `/function/hierarchy/:id?`,
      REQUISITIONS: `/function/requisitions/:id?`,
      ROADMAP: `/function/roadmap/:id?`,
      TALENT: {
        ANY: `/function/talent/:id?/:subtab?`,
        GENERAL: `/function/talent/:id?/general`,
        PERFORMANCE: `/function/talent/:id?/performance`,
        PROMOTION_NOMINATIONS: `/function/talent/:id?/promotion-nominations`,
      },
      ENGAGEMENT: {
        ANY: `/function/engagement/:id/:subtab?`,
        CATEGORIES: `/function/engagement/:id/categories`,
        QUESTIONS: `/function/engagement/:id/questions`,
        COMMENTS: `/function/engagement/:id/comments`,
      },
      PLAYBOOKS: `/function/playbooks/:id?`,
      BUDGET: `/function/budget/:id?`,
      ANALYTICS_DASHBOARDS: `/function/analytics-dashboards/:id`,
    },
    COMPANY_GOALS: {
      ANY: `/company-goals/:tab/:id?`,
      GENERAL: `/company-goals/edit/:id?`,
      PREVIEW: `/company-goals/preview/:id?`,
    },
    COMPANY_GOALS_WEIGHTS: {
      ANY: '/company-goals-weights/:tab',
      GENERAL: '/company-goals-weights/general',
    },
    ENGAGEMENT: {
      ANY: '/engagement/:subtab/:step?/:id?',
      SELECT_NEW: '/engagement/new',
      EDIT: '/engagement/edit/:step/:id?',
    },
    GOAL: {
      ANY: '/goal/:tab/:id?',
      EDIT: '/goal/edit/:id',
      CREATE: {
        FORM: '/goal/create/:id',
      },
      PREVIEW: '/goal/preview/:id',
    },
    CASCADE_GOAL: '/cascade-goal/edit/:id?',
    KPI: {
      ANY: `/KPI/:tab/:id?`,
      PREVIEW: `/KPI/general/:id?`,
      GENERAL: `/KPI/edit/:id?`,
      TARGETS_SETTING: `/KPI/targets-setting/:id?`,
      SELECT_TYPE: `/KPI/select-type`,
      CHANGELOG: `/KPI/changelog/:id?`,
    },
    KPI_TARGET: {
      ANY: `/KPI-target/:kpiId/:tab/:id?`,
      VALUE: `/KPI-target/:kpiId/value/:id?`,
      ROADMAP: `/KPI-target/:kpiId/roadmap/:id?`,
    },
    KPI_TEMPLATES: {
      ANY: `/KPI-templates/:tab`,
      TEMPLATES: `/KPI-templates/templates`,
      RULES: `/KPI-templates/rules`,
    },
    KPI_TEMPLATE: {
      ANY: `/KPI-template/:tab/:id?`,
      GENERAL: `/KPI-template/general/:id?`,
    },
    KPI_RULE: {
      ANY: `/KPI-rule/:tab/:id?`,
      GENERAL: `/KPI-rule/general/:id?`,
    },
    KPI_WEIGHTS: {
      ANY: `/KPI-weights/:tab/:id?`,
      GENERAL: `/KPI-weights/general/:id?`,
    },
    ASSIGN_KPIS: {
      ANY: `/assign_kpis/:entity/:id/:notificationId?`,
      PERSONAL: `/assign_kpis/employee/:id/:notificationId?`,
      TEAM: `/assign_kpis/team/:id/:notificationId?`,
      DEPARTMENT: `/assign_kpis/department/:id/:notificationId?`,
    },
    APPROVE_KPIS: {
      ANY: `/approve_kpis/:entity/:id/:notificationId`,
      TEAM: `/approve_kpis/team/:id/:notificationId`,
      DEPARTMENT: `/approve_kpis/department/:id/:notificationId`,
    },
    SKILL: {
      ANY: `/skill/:tab/:id?`,
      GENERAL: `/skill/general/:id?`,
      PREVIEW: `/skill/preview/:id?`,
      TALENT: `/skill/talent/:id?`,
      SCORECARD_PREVIEW: `/skill/scorecard/:id?`,
      SPECIALISATIONS: `/skill/specialisations/:id?`,
    },
    VALUE: {
      ANY: `/value/:tab/:id?`,
      GENERAL: `/value/general/:id?`,
      PREVIEW: `/value/preview/:id?`,
      SCORECARD_PREVIEW: `/value/scorecard/:id?`,
    },
    REVIEW_CYCLES: {
      ANY: `/review-cycles/:tab/:id?`,
      GENERAL: `/review-cycles/general/:id?`,
      GENERAL_INFO: `/review-cycles/general-info/:id?`,
      TIMELINE: `/review-cycles/timeline/:id?`,
      ELIGIBLE_EMPLOYEES_LIST: `/review-cycles/eligible-employees/list`,
      ELIGIBLE_EMPLOYEES: `/review-cycles/eligible-employees/:id?`,
      SCORECARDS: `/review-cycles/scorecards/:id?`,
      GRADE: `/review-cycles/grade/:id?`,
    },
    PROBATION: {
      TEMPLATE: `/probation/templates-form/:id?`,
      TEMPLATE_VIEW: `/probation/templates-view/:id`,
    },
    ENTITY: {
      ANY: `/entity/:tab/:id?`,
      GENERAL: `/entity/general/:id?`,
    },
    SENIORITY: {
      ANY: `/seniority/:tab/:id?`,
      GENERAL: `/seniority/general/:id?`,
    },
    JOB_POSTING: {
      ANY: `/job-posting/:tab/:specId?/:id?`,
      GENERAL: `/job-posting/general/:specId?/:id?`,
      PREVIEW: `/job-posting/preview/:specId?/:id?`,
      APPLICATION_FORM: `/job-posting/application-form/:specId?/:id?`,
      AUTOMATION: `/job-posting/automation/:specId?/:id?`,
      BOOKING_LINKS: `/job-posting/booking-links/:specId?/:id?`,
      REQUISITIONS: `/job-posting/requisitions/:specId?/:id?`,
      LINKEDIN_POSTINGS: '/job-posting/linked-in-postings/:specId?/:id?',
      CANDIDATES: '/job-posting/candidates/:specId?/:id?',
    },
    JOB_POSTING_FLOW: {
      ANY: '/job-posting-flow/:tab/:specId?/:id?',
      DETAILS: '/job-posting-flow/details/:specId?/:id?',
      APPLICATION_FORM: '/job-posting-flow/application-form/:specId?/:id?',
      HIRING_STAGES: '/job-posting-flow/hiring-stages/:specId?/:id?',
    },
    SPECIALISATION_HIRING_PROCESS: {
      ANY: '/specialisation-hiring-process/:tab/:specialisationId/:hiringProcessId/:id?',
      GENERAL: `/specialisation-hiring-process/general/:specialisationId/:hiringProcessId/:id?`,
      HIRING_STAGE: `/specialisation-hiring-process/hiring-stage/:specialisationId/:hiringProcessId/:id?`,
    },
    RECRUITMENT_GROUPS: {
      ANY: `/recruitment-groups/:tab/:id?`,
      ALL: `/recruitment-groups/all`,
      GENERAL: `/recruitment-groups/general/:id?`,
    },
    PERMISSION_GROUPS: {
      ANY: `/permission-groups/:tab/:id?`,
      GENERAL: `/permission-groups/general/:id?`,
    },
    ACCESS_REQUESTS: {
      ANY: `/access-requests/employee/:employeeId/:tab/:id?`,
      ROUTER: `/access-requests/employee/:employeeId/router`,
      LOOKER_MODEL: `/access-requests/employee/:employeeId/looker-model/:id?`,
      LOOKER_FOLDER: `/access-requests/employee/:employeeId/looker-folder/:id?`,
      GENERAL: `/access-requests/employee/:employeeId/general/:id?`,
    },
    HIRING_STAGES: {
      ANY: `/hiring-stage/:tab/:id?`,
      GENERAL: `/hiring-stage/general/:id?`,
      SPECIALISATION: `/hiring-stage/specialisation/:id?`,
      ROLE: `/hiring-stage/role/:id?`,
    },
    TEAM_ACCESS_REQUESTS: {
      ANY: `/team-access-requests/team/:teamId/:tab/:id?`,
      GENERAL: `/team-access-requests/team/:teamId/general/:id?`,
    },
    REFERRAL_BONUSES: {
      ANY: `/referral-bonuses/:tab/:id?`,
      ALL: `/referral-bonuses/list`,
      GENERAL: `/referral-bonuses/general/:id?`,
    },
    SCREEN_CALL: {
      ANY: `/screen-call/:tab?/:id?`,
      VIEW: `/screen-call/view/:id?`,
    },
    HOME_TASK: {
      ANY: `/home-task/:tab?/:id?`,
      DEFINE: `/home-task/define/:id?`,
      VIEW: `/home-task/view/:id?`,
    },
    BAR_RAISER: {
      ANY: `/bar-raiser/:tab?/:id?`,
      VIEW: `/bar-raiser/view/:id?`,
    },
    HIRING_MANAGEMENT: {
      ANY: `/hiring-management/:tab?/:id?`,
      VIEW: `/hiring-management/view/:id?`,
    },
    BUSINESS_INTERVIEW: {
      ANY: `/business-interview/:tab?/:id?`,
      VIEW: `/business-interview/view/:id?`,
    },
    PROCESS_BUILDING: {
      ANY: `/process-building/:tab?/:id?`,
      VIEW: `/process-building/view/:id?`,
    },
    PROJECT_MANAGEMENT: {
      ANY: `/project-management/:tab?/:id?`,
      VIEW: `/project-management/view/:id?`,
    },
    PROBLEM_SOLVING: {
      ANY: `/problem-solving/:tab?/:id?`,
      VIEW: `/problem-solving/view/:id?`,
    },
    HIRING_PANEL: {
      ANY: `/hiring-panel/:tab?/:id?`,
      VIEW: `/hiring-panel/view/:id?`,
    },
    REFERENCE_CHECK: {
      ANY: `/reference-check/:tab?/:id?`,
      VIEW: `/reference-check/view/:id?`,
    },
    SKILL_INTERVIEW: {
      ANY: `/skill-interview/:tab?/:id?`,
      VIEW: `/skill-interview/view/:id?`,
    },
    /**
     * @deprecated use CANDIDATE instead
     */
    INTERVIEW_TOOL_CANDIDATE: {
      ANY: '/interview-tool-candidate/:id?/:subtab?',
      SUMMARY: '/interview-tool-candidate/:id/summary',
      FORMS: '/interview-tool-candidate/:id/forms',
      EMAILS: '/interview-tool-candidate/:id/emails',
      COMMENTS: '/interview-tool-candidate/:id/comments',
      TIMELINE: '/interview-tool-candidate/:id/timeline',
      COMPETENCIES: '/interview-tool-candidate/:id/competencies',
      DOCUMENTS: '/interview-tool-candidate/:id/documents',
    },
    CANDIDATE: {
      ANY: '/candidate/:id?/:subtab?',
      SUMMARY: '/candidate/:id/summary',
      PERFORMANCE: '/candidate/:id/performance',
      FORMS: '/candidate/:id/forms',
      EMAILS: '/candidate/:id/emails',
      COMMENTS: '/candidate/:id/comments',
      TIMELINE: '/candidate/:id/timeline',
      COMPETENCIES: '/candidate/:id/competencies',
      DOCUMENTS: '/candidate/:id/documents',
    },
    SCHEDULE_INTERVIEW: {
      ANY: '/schedule-interview/:tab/:candidateId/:roundId',
      SLOTS: '/schedule-interview/slots/:candidateId/:roundId',
      MANUAL: '/schedule-interview/manual/:candidateId/:roundId',
    },
    CANDIDATE_ADD_DOCUMENT: '/candidate-add-document/:id?',
    /**
     * @deprecated use INTERVIEW_FEEDBACK instead
     */
    INTERVIEW_TOOL_SCORECARD: {
      ANY: '/interview-tool-scorecard/:tab?/:id?',
      GENERAL: '/interview-tool-scorecard/general/:id?',
    },
    INTERVIEW_FEEDBACK: {
      ANY: '/interview-feedback/:tab?/:id?',
      GENERAL: '/interview-feedback/general/:id?',
    },
    INTERVIEW_DASHBOARD: {
      PENDING: `/interviews/pending`,
      COMPLETED: `/interviews/completed`,
    },
    INTERVIEW_AVAILABILITY: '/interview-availability',
    HIRING: {
      ANY: `/hiring/:subtab?`,
      REQUISITIONS: `/hiring/requisitions`,
      CANDIDATES: `/hiring/candidates`,
    },
    MEETINGS_WITH: {
      ANY: `/meetings/:employeeId/with/:companionId/:subtab?`,
      UPCOMING: `/meetings/:employeeId/with/:companionId/upcoming`,
      PAST: `/meetings/:employeeId/with/:companionId/past`,
    },
    ELIGIBLE_PEOPLE_EDIT: {
      ANY: `/eligible-people-edit/:subtab?/:id?`,
      GENERAL: `/eligible-people-edit/general/:id`,
    },
    IMPORT_DATA: {
      EMPLOYEES: {
        ANY: `/import/employees/:id?`,
        UPLOAD_FILE: `/import/employees`,
        SESSION: `/import/employees/:id`,
      },
      CANDIDATES: {
        ANY: `/import/candidates/:id?`,
        UPLOAD_FILE: `/import/candidates`,
        SESSION: `/import/candidates/:id`,
      },
      EXTERNAL_EMPLOYEES: {
        ANY: `/import/external-employees/:id?`,
        UPLOAD_FILE: `/import/external-employees`,
        SESSION: `/import/external-employees/:id`,
      },
      KPIS: {
        ANY: `/import/kpis/:subtab?/:id?`,
        UPLOAD_FILE: `/import/kpis`,
        REVIEW_DATA: `/import/kpis/review-data/:id`,
        STATUS: `/import/kpis/status/:id`,
      },
      DEPARTMENT_BUDGET: {
        ANY: `/import/department-budget/:tab/:id/:sessionId?`,
        UPLOAD_FILE: `/import/department-budget/upload/:id`,
        REVIEW_DATA: `/import/department-budget/review-data/:id/:sessionId`,
      },
      BONUSES: {
        ANY: `/import/bonuses/:tab/:id?`,
        UPLOAD_FILE: `/import/bonuses/upload`,
        REVIEW_DATA: `/import/bonuses/review-data/:id`,
      },
      COMPENSATION_BANDS: {
        ANY: `/import/compensation-bands/:tab/:id?`,
        UPLOAD_FILE: `/import/compensation-bands/upload`,
        REVIEW_DATA: `/import/compensation-bands/review-data/:id`,
      },
      DOCUMENTS: {
        ANY: `/import/documents/:id?`,
        UPLOAD_FILE: `/import/documents`,
        SESSION: `/import/documents/:id`,
      },
      TIME_OFF: {
        ANY: `/import/time-off/:route/:id?`,
        UPLOAD_FILE: `/import/time-off/import`,
        UPLOAD_REQUESTS_FILE: `/import/time-off/import-requests`,
        REQUESTS_SESSION: `/import/time-off/requests/:id`,
        BALANCES_SESSION: `/import/time-off/balances/:id`,
      },
      TIME_OFF_BALANCES: {
        ANY: `/import/time-off-balances/:route/:id?`,
        IMPORT: `/import/time-off-balances/import`,
        SESSION: `/import/time-off-balances/session/:id`,
      },
      CONTRACTS: {
        ANY: `/import/contracts/:tab/:id?`,
        UPLOAD_FILE: `/import/contracts/upload`,
        REVIEW_DATA: `/import/contracts/review-data/:id`,
      },
      GOALS: {
        ANY: `/import/goals/:tab/:id?`,
        IMPORT_TYPE: '/import/goals/import-type',
        IMPORT: `/import/goals/import`,
        SESSION: `/import/goals/session/:id`,
      },
      GRANTS: {
        ANY: `/import/grants/:route/:id?`,
        IMPORT: `/import/grants/import`,
        SESSION: `/import/grants/session/:id`,
      },
    },
    APPLICATION_CANDIDATE: {
      ANY: '/application-candidate/:tab?/:id?',
      PREVIEW: '/application-candidate/preview/:id?',
    },
    NEW_CANDIDATE: {
      ANY: '/new-candidate/:tab?/:id?',
      GENERAL: '/new-candidate/general',
      PREVIEW: '/new-candidate/preview/:id?',
    },
    REFER_CANDIDATE: {
      ANY: '/refer-candidate/:tab?/:id?',
      NEW: '/refer-candidate/new',
      PREVIEW: '/refer-candidate/preview/:id?',
    },
    JOB_POSTING_AUTOMATION: {
      ANY: `/job-posting-automation/:tab/:jobPostingId/:id?`,
      DETAILS: `/job-posting-automation/details/:jobPostingId/:id?`,
      CANDIDATES: `/job-posting-automation/candidates/:jobPostingId/:id?`,
    },
    ARCHIVE_OPPORTUNITY: {
      ANY: '/archive-opportunity/:tab?/:id?',
      GENERAL: '/archive-opportunity/general/:id?',
      EMAIL: '/archive-opportunity/email/:id?',
    },
    ANONYMIZE_CANDIDATE: {
      ANY: '/anonymize-candidate/:tab?/:id?',
      GENERAL: '/anonymize-candidate/general/:id?',
    },
    HIRING_STAGE: {
      ANY: '/hiring-stage/:tab?/:id?',
      GENERAL: '/hiring-stage/general/:id?',
    },
    SEND_EMAIL: {
      ANY: `/send-email/:tab?/:candidateId?/:threadId?/:emailId?`,
      CANDIDATE: `/send-email/candidate/:candidateId/:threadId?/:emailId?`,
      CANDIDATE_DATA: `/send-email/candidate-data/:candidateId/:threadId?/:emailId?`,
      VIEW: `/send-email/view/:candidateId/:threadId/:emailId`,
    },
    SEND_EMPLOYEE_EMAIL: {
      ANY: `/send-employee-email/:tab?/:employeeId?/:threadId?/:emailId?`,
      EMPLOYEE: `/send-employee-email/employee/:employeeId/:threadId?/:emailId?`,
      VIEW: `/send-employee-email/view/:employeeId/:threadId/:emailId`,
    },
    SEND_ONLINE_TEST: `/send-online-test/:candidateId/:roundId/:stageId`,
    PROMOTION_NOMINATION: {
      ANY: `/promotion-nomination/:employeeId/:tab/:id?/:stageId?`,
      MANAGER_FORM: `/promotion-nomination/:employeeId/manager-form/:id?`,
      MANAGER_FORM_VIEW: `/promotion-nomination/:employeeId/manager-form/:id?/view`,
      PREVIEW: `/promotion-nomination/:employeeId/preview/:id?`,
      STAGE: `/promotion-nomination/:employeeId/stage/:id?/:stageId?`,
    },
    OFFER_PLACEHOLDER: {
      ANY: '/offer-placeholder/:tab?/:id?',
      GENERAL: '/offer-placeholder/general/:id?',
    },
    OFFER_TEMPLATE: {
      ANY: '/offer-template/:tab?/:id?',
      GENERAL: '/offer-template/general/:id?',
    },
    LEADERSHIP: `/leadership/:entityType/:entityId/general/:id?`,
    OFFER_CREATION: {
      ANY: '/offer-creation/:tab?/:candidateId?/:id?',
      GENERAL: '/offer-creation/general/:candidateId?/:id?',
      PREVIEW: '/offer-creation/preview/:candidateId?/:id?',
      CHANGELOG: '/offer-creation/changelog/:candidateId?/:id?',
    },
    ACCESS_MANAGEMENT_USER: {
      ANY: `/access-management-user/:tab?/:id?`,
      GENERAL: `/access-management-user/general/:id?`,
      GROUPS: `/access-management-user/groups/:id?`,
      PERMISSIONS: `/access-management-user/permissions/:id?`,
    },
    ACCESS_MANAGEMENT_GROUP: {
      ANY: `/access-management-group/:tab?/:id?`,
      GENERAL: `/access-management-group/general/:id?`,
      DETAILS: `/access-management-group/details/:id?`,
      USERS: `/access-management-group/users/:id?`,
      RECERTIFY: `/access-management-group/recertify/:id`,
    },
    ACCESS_MANAGEMENT_PERMISSION: {
      ANY: `/access-management-permission/:tab?/:id?`,
      DETAILS: `/access-management-permission/details/:id?`,
      USERS: `/access-management-permission/users/:id?`,
    },
    ADD_SERVICE_ACCOUNT: {
      ANY: `/add-service-account/:tab?/:id?`,
      GENERAL: `/add-service-account/general/:id?`,
    },
    ASSIGN_USER_TO_PERMISSION: {
      ANY: `/assign-user-to-permission/:tab?/:userId?/:id?`,
      GENERAL: `/assign-user-to-permission/general/:userId/:id?`,
    },
    ASSIGN_USER_TO_PERMISSION_GROUP: {
      ANY: `/assign-user-to-permission-group/:tab?/:userId?/:id?`,
      GENERAL: `/assign-user-to-permission-group/general/:userId/:id?`,
    },
    ASSIGN_USER_TO_PERMISSION_GROUPS: {
      ANY: `/assign-user-to-permission-groups/:tab?/:userId?`,
      GENERAL: `/assign-user-to-permission-groups/general/:userId?`,
    },
    GROUP_ACCESS_REQUEST: {
      ANY: `/request-group-access/:tab?/:id?`,
      GENERAL: `/request-group-access/general/:id?`,
      VIEW: `/request-group-access/view/:id?`,
    },
    ASSIGN_PERMISSION_GROUP_TO_USER: {
      ANY: `/assign-permission-group-to-user/:tab?/:groupId?/:id?`,
      GENERAL: `/assign-permission-group-to-user/general/:groupId/:id?`,
    },
    CV_SCREENING: `/cv-screening/:id`,
    CV_SCREENING_SUMMARY: `/cv-screening-summary`,
    COMMUNICATION_GROUPS: {
      ANY: `/communication-groups/:action/:id?/:tab?`,
      EDIT: `/communication-groups/edit/:id?`,
      VIEW: {
        ANY: `/communication-groups/view/:id?/:tab?`,
        GROUP: `/communication-groups/view/:id`,
        MEMBERS: `/communication-groups/view/:id/members`,
      },
    },
    ADD_INTERVIEW_ROUND: {
      ANY: `/add-interview-round/:candidateId?/:tab?`,
      GENERAL: `/add-interview-round/:candidateId?/general`,
    },
    CONTRACTS_BULK_REVIEW: `/contracts/:id/:employeeId/review`,
    CONTRACT_TYPE: {
      ANY: `/contract-type/:tab/:id?`,
      GENERAL: `/contract-type/general/:id?`,
    },
    QUERY: {
      ANY: `/analytics-query/:tab/:id?`,
      GENERAL: `/analytics-query/edit/:id?`,
      META_DATA: `/analytics-query/metadata/:id`,
      PERMISSIONS: `/analytics-query/permissions/:id`,
      RUN_HISTORY: `/analytics-query/history/:id`,
    },
    DATA_ANALYTICS_CONNECTION: {
      ANY: `/analytics-connection/:tab?/:id?`,
      GENERAL: `/analytics-connection/edit/:id?`,
      PERMISSIONS: `/analytics-connection/permissions/:id`,
    },
    DATA_ANALYTICS_REPORT: {
      ANY: `/analytics-report/:tab?/:id?`,
      CONTENT: `/analytics-report/content/:id?`,
      FORMAT: `/analytics-report/format/:id?`,
      TARGET: `/analytics-report/target/:id?`,
    },
    VIEW_DATA_ANALYTICS_REPORT: {
      ANY: `/view-analytics-report/:tab?/:id`,
      DETAILS: `/view-analytics-report/details/:id`,
      PREVIEW: `/view-analytics-report/preview/:id`,
    },
    DATA_ANALYTICS_DASHBOARD_CREATE: `/create-analytics-dashboard`,
    DATA_ANALYTICS_DASHBOARD_PERMISSIONS: `/analytics-dashboard-permissions/:id`,
    DATA_ANALYTICS_DASHBOARD: {
      ANY: `/analytics-dashboard/:tab?/:id?`,
      DETAILS: `/analytics-dashboard/details/:id?`,
      PREVIEW: `/analytics-dashboard/preview/:id?`,
    },
    DATA_ANALYTICS_INTERNAL_DASHBOARD: {
      ANY: `/analytics-internal-dashboard/:tab?/:id?`,
      DETAILS: `/analytics-internal-dashboard/details/:id?`,
      METRICS: `/analytics-internal-dashboard/metrics/:id?`,
    },
    DATA_ANALYTICS_INTERNAL_DASHBOARD_VIEW: `/view-analytics-internal-dashboard/:id`,
    VIEW_DATA_ANALYTICS_DASHBOARD: `/view-analytics-dashboard/:id`,
    PAY_GROUP_BULK_EDIT: `/payroll/bulk-edit/pay-groups/edit`,
    EMPLOYEES_BULK_IMPORT: `/bulk-import/employees`,
    EMPLOYEES_ORG_CHART: `/employees-org-chart`,
    BOOKING_LINKS: '/booking-links',
    CRM: '/crm',
    CANDIDATE_BULK_ACTIONS: {
      ANY: `/bulk-actions/:tab`,
      CANDIDATE_EMAILS: `/bulk-actions/candidate-emails`,
      CANDIDATE_ARCHIVE: `/bulk-actions/candidate-archive`,
      CANDIDATE_EDIT: `/bulk-actions/candidate-edit`,
      CANDIDATE_MOVE_STAGE: `/bulk-actions/candidate-move-stage`,
      CANDIDATE_SEND_ONLINE_TEST: `/bulk-actions/candidate-send-online-test`,
      CANDIDATE_SNOOZE: `/bulk-actions/candidate-snooze`,
      CANDIDATE_UNSNOOZE: `/bulk-actions/candidate-unsnooze`,
    },
    EMAIL_TEMPLATES: `/email-templates`,
    STAGES_BANK: `/stages-bank`,
    INTERVIEW_SCORECARD_TEMPLATES: `/scorecard-templates`,
    OFFER_FORMS: {
      ANY: `/offer-forms/:tab`,
      OFFER_PLACEHOLDERS: `/offer-forms/offer-placeholders`,
      OFFER_TEMPLATES: `/offer-forms/offer-templates`,
      OFFERS: `/offer-forms/offers`,
    },
  },
  PEOPLE: {
    ANY: `${PEOPLE}/:tab?/:subtab?`,
    EMPLOYEES: `${PEOPLE}/employees`,
    DOCUMENTS: `${PEOPLE}/documents`,
    PAYROLL: `${PEOPLE}/payroll`,
    TIME_MANAGEMENT: `${PEOPLE}/time-management`,
  },
  ADMIN: {
    ANY: `${ADMIN}/:tab?/:subtab?`,
    FEEDBACK: `${ADMIN}/feedback`,
    ACCESS_MANAGEMENT: {
      ANY: `${ADMIN}/access-management/:tab?`,
      USERS: `${ADMIN}/access-management/users`,
      GROUPS: `${ADMIN}/access-management/groups`,
      PERMISSIONS: `${ADMIN}/access-management/permissions`,
    },
  },
  RECRUITMENT: {
    ANY: `${RECRUITMENT}/:tab?/:subtab?`,
    JOBS: {
      ANY: `${RECRUITMENT}/jobs/:subtab?`,
      REQUISITIONS: `${RECRUITMENT}/jobs/requisitions`,
      JOB_POSTINGS: `${RECRUITMENT}/jobs/job-postings`,
    },
    CANDIDATES: `${RECRUITMENT}/candidates`,
    INTERVIEWS: `${RECRUITMENT}/interviews`,
  },
  ORGANISATION: {
    ANY: `${ORGANISATION}/:tab?/:subtab?`,
    TEAMS: {
      ANY: `${ORGANISATION}/teams/:subtab?`,
      TEAMS: `${ORGANISATION}/teams/teams`,
      DEPARTMENTS: `${ORGANISATION}/teams/departments`,
    },
    ROLES: {
      ANY: `${ORGANISATION}/roles/:subtab?`,
      ROLES: `${ORGANISATION}/roles/roles`,
      FUNCTIONS: `${ORGANISATION}/roles/functions`,
      SPECIALISATIONS: `${ORGANISATION}/roles/specialisations`,
    },
  },
  /**
   * @deprecated use ORGANISATION.ROLES instead
   */
  FUNCTION: {
    ANY: `${FUNCTION}/:subtab?`,
    SKILLS: `${FUNCTION}/skills`,
  },
  FEATURES: {
    ANY: `${FEATURES}/:subtab?`,
    APPS: `${FEATURES}/apps`,
    INTEGRATIONS: `${FEATURES}/integrations`,
    INTEGRATION: {
      ANY: `${FEATURES}/integration/:tab?`,
      SLACK: {
        ANY: `${FEATURES}/integration/slack/:subtab?/:id?`,
        ALL: `${FEATURES}/integration/slack`,
        BOT: `${FEATURES}/integration/slack/bot/:id?`,
      },
      JIRA: `${FEATURES}/integration/jira`,
      GOOGLE: `${FEATURES}/integration/google`,
      MICROSOFT: `${FEATURES}/integration/microsoft`,
      DOCUSIGN: `${FEATURES}/integration/docusign`,
      DOCUSIGN_CONFIRMATION: `${FEATURES}/integration/docusign-confirmation`,
    },
  },
  PERFORMANCE: {
    ANY: `${PERFORMANCE}/:tab?/:subtab?/:id?/:type?`,
    ENGAGEMENT: {
      ANY: `${PERFORMANCE}/engagement/:subtab/:id?/:type?`,
      DASHBOARD: `${PERFORMANCE}/engagement/dashboard`,
      DETAILS_OVERVIEW: `${PERFORMANCE}/engagement/overview/:id`,
      DETAILS_RESULTS: {
        ANY: `${PERFORMANCE}/engagement/results/:id/:type?`,
        CATEGORIES: `${PERFORMANCE}/engagement/results/:id/categories`,
        COMMENTS: `${PERFORMANCE}/engagement/results/:id/comments`,
        QUESTIONS: `${PERFORMANCE}/engagement/results/:id/questions`,
      },
    },
    ANALYTICS: {
      ANY: `${PERFORMANCE}/analytics/:subtab`,
      DASHBOARDS: `${PERFORMANCE}/analytics/dashboards`,
      QUERIES: `${PERFORMANCE}/analytics/queries`,
    },
    GOALS: {
      ANY: `${PERFORMANCE}/goals/:subtab`,
      GENERAL: `${PERFORMANCE}/goals/general`,
      TARGETS: `${PERFORMANCE}/goals/targets`,
      ROADMAPS: `${PERFORMANCE}/goals/roadmaps`,
    },
    REVIEWS: {
      ANY: `${PERFORMANCE}/reviews/:subtab`,
      GENERAL: `${PERFORMANCE}/reviews/general`,
      PROBATION: `${PERFORMANCE}/reviews/probation`,
      PIP: `${PERFORMANCE}/reviews/pip`,
    },
    MANAGE_REVIEWS: {
      MANAGE_PROBATION: `${PERFORMANCE}/manage-probation`,
      MANAGE_TEMPLATES: `${PERFORMANCE}/manage-templates`,
      MANAGE_PIP: `${PERFORMANCE}/manage-pip`,
    },
    SCORECARD_PREVIEW: `${PERFORMANCE}/scorecard-preview/:id?`,
    SCORECARD_SETUP: {
      ANY: `${PERFORMANCE}/scorecard-setup/:subtab/:id?`,
      CUSTOM_QUESTIONS_GENERAL_FORM: `${PERFORMANCE}/scorecard-setup/custom-questions/general`,
      CUSTOM_QUESTIONS_FORM: `${PERFORMANCE}/scorecard-setup/custom-questions`,
      SETTINGS: {
        ANY: `${PERFORMANCE}/scorecard-setup/settings/:id?`,
        LANDING: `${PERFORMANCE}/scorecard-setup/settings/landing`,
        GENERAL: `${PERFORMANCE}/scorecard-setup/settings/general`,
        CYCLE: `${PERFORMANCE}/scorecard-setup/settings/cycle`,
        REVIEWS: `${PERFORMANCE}/scorecard-setup/settings/reviews`,
        PIP: `${PERFORMANCE}/scorecard-setup/settings/pip`,
        PROBATION: `${PERFORMANCE}/scorecard-setup/settings/probation`,
        PROBATION_TEMPLATE: `${PERFORMANCE}/scorecard-setup/settings/probation-template`,
      },
    },
    REVIEWS_CALIBRATION: `${PERFORMANCE}/reviews-calibration`,
    REVIEWS_CYCLES: `${PERFORMANCE}/reviews-cycles`,
    SETTINGS: {
      ANY: `${PERFORMANCE}/settings/:subtab`,
      LANDING: `${PERFORMANCE}/settings/landing`,
      GENERAL: `${PERFORMANCE}/settings/general`,
      CYCLE: `${PERFORMANCE}/settings/cycle`,
      REVIEWS: `${PERFORMANCE}/settings/reviews`,
      PIP: `${PERFORMANCE}/settings/pip`,
      PROBATION: `${PERFORMANCE}/settings/probation`,
    },
    KPI: {
      ANY: `${PERFORMANCE}/kpi/:subtab`,
      GOALS: `${PERFORMANCE}/kpi/goals`,
      COMPANY: `${PERFORMANCE}/kpi/company`,
      DEPARTMENT: `${PERFORMANCE}/kpi/department`,
    },
    KPIS: `${PERFORMANCE}/kpis`,
    PROMOTION_NOMINEES: `${PERFORMANCE}/promotion-nominees`,
    WHITELIST: `${PERFORMANCE}/whitelist`,
    /**
     * @deprecated use PERFORMANCE_REVIEWS instead
     */
    PERFORMANCE_REVIEWS_DEPRECATED: {
      ANY: `${PERFORMANCE}/performance-reviews/:subtab`,
      REVIEW_CYCLES: `${PERFORMANCE}/performance-reviews/review-cycles`,
      GRADE_CALIBRATION: `${PERFORMANCE}/performance-reviews/grade-calibration/`,
      ELIGIBLE_EMPLOYEES: `${PERFORMANCE}/performance-reviews/eligible-employees/`,
      WHITELIST: `${PERFORMANCE}/performance-reviews/whitelist/`,
    },
    /**
     * @deprecated use PROBATION instead
     */
    PROBATION_DEPRECATED: {
      ANY: `${PERFORMANCE}/probation/:subtab?`,
      EMPLOYEES: `${PERFORMANCE}/probation/employees/`,
      TEMPLATES: `${PERFORMANCE}/probation/templates/`,
    },
    PERFORMANCE_REVIEWS: `${PERFORMANCE}/performance-reviews`,
    PROBATION: `${PERFORMANCE}/probation`,
    PIP: `${PERFORMANCE}/pip`,
    ROADMAPS: `${PERFORMANCE}/roadmaps`,
    SKILLS: `${PERFORMANCE}/skills`,
    VALUES: `${PERFORMANCE}/values`,
    /**
     * @deprecated use PERFORMANCE.REVIEWS_CYCLES instead
     */
    REVIEW_CYCLES: `${PERFORMANCE}/review-cycles`,
    /**
     * @deprecated use PERFORMANCE_REVIEWS instead
     */
    SUPPORT_TOOL: {
      ANY: `${PERFORMANCE}/support-tool/:subtab?`,
      WHITELIST: `${PERFORMANCE}/support-tool/whitelist/`,
      GRADE_CALIBRATION: `${PERFORMANCE}/support-tool/grade-calibration/`,
      ELIGIBLE_EMPLOYEES: `${PERFORMANCE}/support-tool/eligible-employees/`,
    },
  },
  ONBOARDING: {
    ANY: `${ONBOARDING}/:id?/:subtab?/:itemId?`,
    START: `${ONBOARDING}/:id/start`,
    BASIC_INFO: `${ONBOARDING}/:id/basic-info`,
    PERSONAL_INFO: `${ONBOARDING}/:id/personal-info`,
    PAYROLL: `${ONBOARDING}/:id/payroll`,
    DIVERSITY: `${ONBOARDING}/:id/diversity`,
    DOCUMENTS: `${ONBOARDING}/:id/documents`,
    DOCUMENT_UPLOAD_REQUEST: `${ONBOARDING}/:employeeId/document-upload-request/:id`,
    DOCUMENT_EMBEDDED: `${ONBOARDING}/:employeeId/document-embedded/:id`,
    DOCUMENT_DOCUSIGN: `${ONBOARDING}/:employeeId/document-docusign/:id`,
    SUMMARY: `${ONBOARDING}/:id/summary`,
    WELCOME: `${ONBOARDING}/:id/welcome`,
  },
  ONBOARDING_V2: {
    ANY: `${ONBOARDING_V2}/:id/:onboardingId/:subtab/:itemId?`,
    START: `${ONBOARDING_V2}/:id/:onboardingId/start`,
    BASIC_INFO: `${ONBOARDING_V2}/:id/:onboardingId/basic-info`,
    PERSONAL_INFO: `${ONBOARDING_V2}/:id/:onboardingId/personal-info`,
    PAYROLL: `${ONBOARDING_V2}/:id/:onboardingId/payroll`,
    DIVERSITY: `${ONBOARDING_V2}/:id/:onboardingId/diversity`,
    DOCUMENTS: `${ONBOARDING_V2}/:id/:onboardingId/documents`,
    DOCUMENT_UPLOAD_REQUEST: `${ONBOARDING_V2}/:id/:onboardingId/document-upload-request/:itemId`,
    DOCUMENT_EMBEDDED: `${ONBOARDING_V2}/:id/:onboardingId/document-embedded/:itemId`,
    DOCUMENT_DOCUSIGN: `${ONBOARDING_V2}/:id/:onboardingId/document-docusign/:itemId`,
    SUMMARY: `${ONBOARDING_V2}/:id/:onboardingId/summary`,
  },
  /** @deprecated */
  PLATFORM_ONBOARDING: {
    ANY: `${PLATFORM_ONBOARDING}/*`,
    SETUP_STEPS: {
      ANY: `${PLATFORM_ONBOARDING}/setup-steps/:tab`,
    },
    INVITE_TEAM: `${PLATFORM_ONBOARDING}/invite-team`,
  },
  INVITE_TEAM: {
    ANY: `/invite-team/:tab/:action?`,
    EMAILS: `/invite-team/emails/:action?`,
    REVIEW: `/invite-team/review`,
  },
  PLANS: {
    ANY: `/plans/*`,
    SELECT_PLAN: `/plans/select-plan`,
    CHECKOUT: `/plans/checkout`,
    PAYMENT_DETAILS: `/plans/payment-details`,
    INVOICES: `/plans/invoices`,
    INVOICE_DETAILS: `/plans/invoice-details/:id`,
  },
  PREFERENCES: {
    ANY: `${PREFERENCES}/:tab?`,
    ALL: `${PREFERENCES}/all`,
    PRIVACY: `${PREFERENCES}/privacy`,
  },
  ONBOARDING_CHECKLIST: {
    ANY: `${ONBOARDING_CHECKLIST}/:page?`,
    ALL: `${ONBOARDING_CHECKLIST}/all`,
    WELCOME: `${ONBOARDING_CHECKLIST}/welcome`,
    INITIAL: {
      ANY: `${ONBOARDING_CHECKLIST}/initial/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/initial/intro`,
      PREFERENCES: `${ONBOARDING_CHECKLIST}/initial/preferences`,
      INTEGRATIONS: `${ONBOARDING_CHECKLIST}/initial/integrations`,
      HR_APPS: `${ONBOARDING_CHECKLIST}/initial/hr-apps`,
      PERFORMANCE_APPS: `${ONBOARDING_CHECKLIST}/initial/performance-apps`,
      RECRUITMENT_APPS: `${ONBOARDING_CHECKLIST}/initial/recruitment-apps`,
    },
    EMPLOYEES: {
      ANY: `${ONBOARDING_CHECKLIST}/employees/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/employees/intro`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/employees/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/employees/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/employees/upload/session/:id`,
      },
    },
    REQUISITIONS: {
      ANY: `${ONBOARDING_CHECKLIST}/requisitions/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/requisitions/intro`,
      APPROVALS: `${ONBOARDING_CHECKLIST}/requisitions/approvals`,
      SETTINGS: `${ONBOARDING_CHECKLIST}/requisitions/settings`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/requisitions/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/requisitions/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/requisitions/upload/session/:id`,
      },
    },
    JOB_POSTINGS: {
      ANY: `${ONBOARDING_CHECKLIST}/job-postings/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/job-postings/intro`,
      APPROVALS: `${ONBOARDING_CHECKLIST}/job-postings/approvals`,
      COMPENSATION_BANDS: `${ONBOARDING_CHECKLIST}/job-postings/compensation-bands`,
      APPLICATION_FORM: `${ONBOARDING_CHECKLIST}/job-postings/application-form`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/job-postings/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/job-postings/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/job-postings/upload/session/:id`,
      },
    },
    CANDIDATES: {
      ANY: `${ONBOARDING_CHECKLIST}/candidates/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/candidates/intro`,
      FREEZING_PERIOD: `${ONBOARDING_CHECKLIST}/candidates/freezing-period`,
      COMMUNICATION: `${ONBOARDING_CHECKLIST}/candidates/communication`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/candidates/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/candidates/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/candidates/upload/session/:id`,
      },
    },
    HIRING_PROCESS: {
      ANY: `${ONBOARDING_CHECKLIST}/hiring-process/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/hiring-process/intro`,
      DEFAULT_HIRING_PROCESS: `${ONBOARDING_CHECKLIST}/hiring-process/default-hiring-process`,
      CUSTOMISE_HIRING_PROCESS: `${ONBOARDING_CHECKLIST}/hiring-process/customise-hiring-process`,
    },
    CAREERS_PAGE: {
      ANY: `${ONBOARDING_CHECKLIST}/careers-page/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/careers-page/intro`,
      PERSONALISATION: `${ONBOARDING_CHECKLIST}/careers-page/personalisation`,
      PUBLISH: `${ONBOARDING_CHECKLIST}/careers-page/publish`,
    },
    TEAMS: {
      ANY: `${ONBOARDING_CHECKLIST}/teams/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/teams/intro`,
      ORGANISATION: `${ONBOARDING_CHECKLIST}/teams/organisation`,
      APPROVALS: `${ONBOARDING_CHECKLIST}/teams/approvals`,
      SETTINGS: `${ONBOARDING_CHECKLIST}/teams/settings`,
      MANAGE_DEPARTMENTS: `${ONBOARDING_CHECKLIST}/teams/manage-departments`,
      MANAGE_TEAMS: `${ONBOARDING_CHECKLIST}/teams/manage-teams`,
    },
    ROLES: {
      ANY: `${ONBOARDING_CHECKLIST}/roles/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/roles/intro`,
      APPROVALS: `${ONBOARDING_CHECKLIST}/roles/approvals`,
      SETTINGS: `${ONBOARDING_CHECKLIST}/roles/settings`,
      FUNCTIONS: `${ONBOARDING_CHECKLIST}/roles/functions`,
      ROLES: `${ONBOARDING_CHECKLIST}/roles/roles`,
      SPECIALISATIONS: `${ONBOARDING_CHECKLIST}/roles/specialisations`,
    },
    LOCATIONS: {
      ANY: `${ONBOARDING_CHECKLIST}/locations/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/locations/intro`,
      LEGAL: `${ONBOARDING_CHECKLIST}/locations/legal`,
      LOCATIONS: `${ONBOARDING_CHECKLIST}/locations/locations`,
    },
    DOCUMENTS: {
      ANY: `${ONBOARDING_CHECKLIST}/documents/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/documents/intro`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/documents/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/documents/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/documents/upload/session/:id`,
      },
    },
    TIME_OFF: {
      ANY: `${ONBOARDING_CHECKLIST}/time-off/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/time-off/intro`,
      POLICIES: `${ONBOARDING_CHECKLIST}/time-off/policies`,
      POLICY_ASSIGNMENTS: `${ONBOARDING_CHECKLIST}/time-off/policy-assignments`,
      BALANCE_ADJUSTMENTS: {
        ANY: `${ONBOARDING_CHECKLIST}/time-off/balance-adjustments/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/time-off/balance-adjustments/import`,
        REQUESTS: `${ONBOARDING_CHECKLIST}/time-off/balance-adjustments/requests/:id`,
        BALANCES: `${ONBOARDING_CHECKLIST}/time-off/balance-adjustments/balances/:id`,
      },
      ELIGIBILITY_GROUPS: `${ONBOARDING_CHECKLIST}/time-off/eligibility-groups`,
    },
    CONTRACTS: {
      ANY: `${ONBOARDING_CHECKLIST}/contracts/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/contracts/intro`,
      SETTINGS: `${ONBOARDING_CHECKLIST}/contracts/settings`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/contracts/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/contracts/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/contracts/upload/session/:id`,
      },
    },
    PERFORMANCE: {
      ANY: `${ONBOARDING_CHECKLIST}/performance/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST}/performance/intro`,
      FREQUENCY: `${ONBOARDING_CHECKLIST}/performance/frequency`,
      TIMELINE: `${ONBOARDING_CHECKLIST}/performance/timeline`,
      CYCLES: `${ONBOARDING_CHECKLIST}/performance/cycles`,
      REVIEWERS: `${ONBOARDING_CHECKLIST}/performance/reviewers`,
      ELIGIBILITY: `${ONBOARDING_CHECKLIST}/performance/eligibility`,
      SCORECARDS: `${ONBOARDING_CHECKLIST}/performance/scorecards`,
      GRADES: `${ONBOARDING_CHECKLIST}/performance/grades`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST}/performance/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST}/performance/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST}/performance/upload/session/:id`,
      },
    },
    KPI: {
      ANY: `${ONBOARDING_CHECKLIST}/kpi/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/kpi/intro`,
      TYPES: `${ONBOARDING_CHECKLIST}/kpi/types`,
      HIERARCHY: `${ONBOARDING_CHECKLIST}/kpi/hierarchy`,
      BI_INTEGRATION: `${ONBOARDING_CHECKLIST}/kpi/bi-integration`,
      PREVIEW: `${ONBOARDING_CHECKLIST}/kpi/preview`,
    },
    SKILLS: {
      ANY: `${ONBOARDING_CHECKLIST}/skills/:step?`,
      INTRO: `${ONBOARDING_CHECKLIST}/skills/intro`,
      APPROVALS: `${ONBOARDING_CHECKLIST}/skills/approvals`,
      PREVIEW: `${ONBOARDING_CHECKLIST}/skills/preview`,
    },
    VALUES: {
      ANY: `${ONBOARDING_CHECKLIST}/values/:step?`,
      INTRO: `${ONBOARDING_CHECKLIST}/values/intro`,
      DEFINITION: `${ONBOARDING_CHECKLIST}/values/definition`,
      PREVIEW: `${ONBOARDING_CHECKLIST}/values/preview`,
    },
    ROADMAPS: {
      ANY: `${ONBOARDING_CHECKLIST}/roadmaps/:step?`,
      INTRO: `${ONBOARDING_CHECKLIST}/roadmaps/intro`,
      JIRA_INTEGRATION: `${ONBOARDING_CHECKLIST}/roadmaps/integration`,
      PREVIEW: `${ONBOARDING_CHECKLIST}/roadmaps/preview`,
    },
    PROBATION: {
      ANY: `${ONBOARDING_CHECKLIST}/probation/:step?`,
      INTRO: `${ONBOARDING_CHECKLIST}/probation/intro`,
      SETUP: `${ONBOARDING_CHECKLIST}/probation/setup`,
      COMMITTEE: `${ONBOARDING_CHECKLIST}/probation/committee`,
      ELIGIBILITY: `${ONBOARDING_CHECKLIST}/probation/eligibility`,
      PREVIEW: `${ONBOARDING_CHECKLIST}/probation/preview`,
      APPLY: `${ONBOARDING_CHECKLIST}/probation/apply`,
    },
    LIFECYCLE: {
      ANY: `${ONBOARDING_CHECKLIST}/lifecycle/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/lifecycle/intro`,
      WELCOME: `${ONBOARDING_CHECKLIST}/lifecycle/welcome`,
      HR_CONTACT: `${ONBOARDING_CHECKLIST}/lifecycle/hr-contact`,
      REQUIRED_INFO: `${ONBOARDING_CHECKLIST}/lifecycle/required-info`,
      COMPLIANCE: `${ONBOARDING_CHECKLIST}/lifecycle/compliance`,
    },
    PAYROLL: {
      ANY: `${ONBOARDING_CHECKLIST}/payroll/:step`,
      INTRO: `${ONBOARDING_CHECKLIST}/payroll/intro`,
      SETTINGS: `${ONBOARDING_CHECKLIST}/payroll/settings`,
    },
    FINISH: {
      ANY: `${ONBOARDING_CHECKLIST}/finish/:step`,
      SETTINGS: `${ONBOARDING_CHECKLIST}/finish/settings`,
      INTEGRATIONS: `${ONBOARDING_CHECKLIST}/finish/integrations`,
      INVITE: `${ONBOARDING_CHECKLIST}/finish/invite`,
    },
  },
  ONBOARDING_CHECKLIST_V2: {
    ANY: `${ONBOARDING_CHECKLIST_V2}/:page?`,
    INVITE_ADMINS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/invite-admins/:step/:action?`,
      EMAILS: `${ONBOARDING_CHECKLIST_V2}/invite-admins/emails/:action?`,
      REVIEW: `${ONBOARDING_CHECKLIST_V2}/invite-admins/review`,
    },
    IMPORT_EMPLOYEES: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/import-employees/:step/:uploadStep?/:id?/:type?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/import-employees/intro`,
      IMPORT: `${ONBOARDING_CHECKLIST_V2}/import-employees/import`,
      SELECT_FLOW: `${ONBOARDING_CHECKLIST_V2}/import-employees/select-flow`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/import-employees/upload/:uploadStep?/:id?/:type?`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/import-employees/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/import-employees/upload/session/:id/:type?`,
      },
    },
    TEAMS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/teams/:step`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/teams/intro`,
      REVIEW: `${ONBOARDING_CHECKLIST_V2}/teams/review`,
    },
    ROLES: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/roles/:step`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/roles/intro`,
      REVIEW: `${ONBOARDING_CHECKLIST_V2}/roles/review`,
    },
    ENGAGEMENT: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/engagement/:step/:subtab?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/engagement/intro`,
      SETUP: `${ONBOARDING_CHECKLIST_V2}/engagement/setup/:id`,
      CUSTOMISE: `${ONBOARDING_CHECKLIST_V2}/engagement/customise/:step/:id`,
    },
    GOALS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/goals/:step/:subtab?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/goals/intro`,
      CYCLE: `${ONBOARDING_CHECKLIST_V2}/goals/cycle`,
      SETUP: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/goals/setup/:subtab?`,
        GENERAL: `${ONBOARDING_CHECKLIST_V2}/goals/setup/general`,
        TARGETS: `${ONBOARDING_CHECKLIST_V2}/goals/setup/targets`,
        ROADMAPS: `${ONBOARDING_CHECKLIST_V2}/goals/setup/roadmaps`,
      },
      GOAL: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/goals/goal/:tab/:id?`,
        CREATE: {
          FORM: `${ONBOARDING_CHECKLIST_V2}/goals/goal/create/:id`,
        },
        EDIT: `${ONBOARDING_CHECKLIST_V2}/goals/goal/edit/:id`,
        PREVIEW: `${ONBOARDING_CHECKLIST_V2}/goals/goal/preview/:id`,
      },
      SETTINGS: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/goals/settings/:tab/:subtab?/:id?`,
        ALL: `${ONBOARDING_CHECKLIST_V2}/goals/settings/all`,
        TARGET_TYPES: `${ONBOARDING_CHECKLIST_V2}/goals/settings/target-types`,
        APPROVAL_PROCESS: `${ONBOARDING_CHECKLIST_V2}/goals/settings/approval-process`,
        ROADMAPS: `${ONBOARDING_CHECKLIST_V2}/goals/settings/roadmaps`,
        INTEGRATIONS: {
          JIRA: `${ONBOARDING_CHECKLIST_V2}/goals/settings/integrations/jira`,
        },
        TEMPLATES: {
          ANY: `${ONBOARDING_CHECKLIST_V2}/goals/settings/templates/:tab/:id?`,
          LIST: `${ONBOARDING_CHECKLIST_V2}/goals/settings/templates/list`,
          EDIT: `${ONBOARDING_CHECKLIST_V2}/goals/settings/templates/list/:id`,
          NEW: `${ONBOARDING_CHECKLIST_V2}/goals/settings/templates/new`,
        },
        RULES: {
          ANY: `${ONBOARDING_CHECKLIST_V2}/goals/settings/rules/:tab/:id?`,
          LIST: `${ONBOARDING_CHECKLIST_V2}/goals/settings/rules/list`,
          EDIT: `${ONBOARDING_CHECKLIST_V2}/goals/settings/rules/list/:id`,
          NEW: `${ONBOARDING_CHECKLIST_V2}/goals/settings/rules/new`,
        },
      },
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/goals/upload/:uploadStep?/:id?`,
        IMPORT_TYPE: `${ONBOARDING_CHECKLIST_V2}/goals/upload/import-type`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/goals/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/goals/upload/session/:id`,
      },
    },
    INTEGRATIONS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/integration/:tab/:subtab?/:id?`,
      SLACK: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/integration/slack/:subtab?/:id?`,
        ALL: `${ONBOARDING_CHECKLIST_V2}/integration/slack`,
        BOT: `${ONBOARDING_CHECKLIST_V2}/integration/slack/bot/:id?`,
      },
    },
    REVIEWS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/reviews/:step/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/reviews/intro`,
      REVIEW_TYPES: `${ONBOARDING_CHECKLIST_V2}/reviews/review-types`,
      SETUP_SCORECARD: `${ONBOARDING_CHECKLIST_V2}/reviews/scorecards-setup`,
    },
    JOBS_EDIT: `${ONBOARDING_CHECKLIST_V2}/jobs-edit/:id`,
    JOBS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/jobs/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/jobs/intro`,
      IMPORT: `${ONBOARDING_CHECKLIST_V2}/jobs/import`,
      PREVIEW: `${ONBOARDING_CHECKLIST_V2}/jobs/preview`,
      NEW: `${ONBOARDING_CHECKLIST_V2}/jobs/new`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/jobs/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/jobs/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/jobs/upload/session/:id`,
      },
    },
    CANDIDATES: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/candidates/:step/:uploadStep?/:id?/:type?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/candidates/intro`,
      IMPORT: `${ONBOARDING_CHECKLIST_V2}/candidates/import`,
      NEW: `${ONBOARDING_CHECKLIST_V2}/candidates/new`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/candidates/upload/:uploadStep?/:id?/:type?`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/candidates/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/candidates/upload/session/:id/:type?`,
      },
    },
    INTERVIEWS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/interviews/:step`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/interviews/intro`,
      SETUP: `${ONBOARDING_CHECKLIST_V2}/interviews/setup`,
    },
    EMPLOYEE_RECORDS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/employee-records/:step/:uploadStep?/:id?/:type?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/employee-records/intro`,
      UPDATE: `${ONBOARDING_CHECKLIST_V2}/employee-records/update`,
      UPDATE_EMPLOYEES: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/employee-records/update-employees/:uploadStep?/:id?/:type?`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/employee-records/update-employees/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/employee-records/update-employees/session/:id/:type?`,
      },
      UPLOAD_EMPLOYEES: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-employees/:uploadStep?/:id?/:type?`,
        SELECT_FLOW: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-employees/select-flow`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-employees/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-employees/session/:id/:type?`,
      },
      UPLOAD_CONTRACTS: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-contracts/:uploadStep?/:id?/:type?`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-contracts/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/employee-records/upload-contracts/session/:id/:type?`,
      },
    },
    TIME_MANAGEMENT: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/time-management/:step/:uploadStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/time-management/intro`,
      SELECT_POLICIES: `${ONBOARDING_CHECKLIST_V2}/time-management/select-policies`,
      CONFIGURATION: `${ONBOARDING_CHECKLIST_V2}/time-management/configuration`,
      CREATE_NEW_POLICY: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/time-management/new-policy/:subtab/:id?`,
        BASICS: `${ONBOARDING_CHECKLIST_V2}/time-management/new-policy/basics/:id?`,
        DETAILS: `${ONBOARDING_CHECKLIST_V2}/time-management/new-policy/details/:id?`,
        REQUESTS: `${ONBOARDING_CHECKLIST_V2}/time-management/new-policy/request/:id?`,
        ELIGIBILITY: `${ONBOARDING_CHECKLIST_V2}/time-management/new-policy/eligibility/:id?`,
        REVIEW: `${ONBOARDING_CHECKLIST_V2}/time-management/new-policy/review/:id?`,
      },
      ASSIGNMENTS: `${ONBOARDING_CHECKLIST_V2}/time-management/assignments`,
      ELIGIBILITY_GROUPS: `${ONBOARDING_CHECKLIST_V2}/time-management/eligibility-groups`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/time-management/upload/:uploadStep?/:id?`,
        IMPORT: `${ONBOARDING_CHECKLIST_V2}/time-management/upload/import`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/time-management/upload/session/:id`,
      },
    },
    DOCUMENTS: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/documents/:step/:subStep?/:id?`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/documents/intro`,
      LIST: `${ONBOARDING_CHECKLIST_V2}/documents/list`,
      UPLOAD: {
        ANY: `${ONBOARDING_CHECKLIST_V2}/documents/upload/:uploadStep?/:id?`,
        FILES: `${ONBOARDING_CHECKLIST_V2}/documents/upload/files`,
        SESSION: `${ONBOARDING_CHECKLIST_V2}/documents/upload/session/:id`,
      },
    },
    PAYROLL: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/payroll/:step`,
      INTRO: `${ONBOARDING_CHECKLIST_V2}/payroll/intro`,
      SETTINGS: `${ONBOARDING_CHECKLIST_V2}/payroll/settings`,
    },
    INVITE_EMPLOYEES: {
      ANY: `${ONBOARDING_CHECKLIST_V2}/invite-employees/:step/:action?`,
      EMAILS: `${ONBOARDING_CHECKLIST_V2}/invite-employees/emails/:action?`,
      REVIEW: `${ONBOARDING_CHECKLIST_V2}/invite-employees/review`,
    },
    COMPLETE: `${ONBOARDING_CHECKLIST_V2}/complete`,
  },
  SETTINGS: {
    ANY: `${SETTINGS}/:tab?`,
    ALL: `${SETTINGS}/all`,
    GENERAL: {
      ANY: `${SETTINGS}/general/:tab?`,
      PREVIEW: `${SETTINGS}/general/view`,
      EDIT: `${SETTINGS}/general/edit`,
    },
    ATTENDANCE: `${SETTINGS}/attendance`,
    ACCESS_MANAGEMENT: `${SETTINGS}/access-management`,
    ACCOUNTS: {
      ANY: `${SETTINGS}/accounts/:tab?`,
      INFORMATION: `${SETTINGS}/accounts/information/:id`,
      INVOICES: `${SETTINGS}/accounts/invoices/:id`,
      INVOICE: `${SETTINGS}/accounts/invoice/:id/:invoiceId`,
      DISCOUNTS: `${SETTINGS}/accounts/discounts/:id`,
      DISCOUNT: `${SETTINGS}/accounts/discount/:id/:subscriptionId/:discountId?`,
      FEATURES: `${SETTINGS}/accounts/features/:id`,
      GENERAL: `${SETTINGS}/accounts/general/:id?`,
      LIST: `${SETTINGS}/accounts/list`,
    },
    PLAN_MANAGEMENT: {
      ANY: `${SETTINGS}/plan-management/:tab?`,
      LIST: `${SETTINGS}/plan-management/list`,
      DETAILS: `${SETTINGS}/plan-management/details/:id`,
    },
    SECURITY: `${SETTINGS}/security`,
    USER_PREFERENCES: `${SETTINGS}/user-preferences`,
    SANDBOX_MODE: `${SETTINGS}/sandbox-mode`,
    CONTRACTS: `${SETTINGS}/contracts`,
    EMPLOYEES: {
      ANY: `${SETTINGS}/employees/:tab`,
      GENERAL: `${SETTINGS}/employees/general`,
      PROFILE: `${SETTINGS}/employees/profile`,
      ATTRIBUTES: `${SETTINGS}/employees/attributes`,
      DATA_RETENTION: `${SETTINGS}/employees/data-retention`,
      ONBOARDING: `${SETTINGS}/employees/onboarding`,
    },
    GROUPS: `${SETTINGS}/groups`,
    LIFECYCLE: `${SETTINGS}/lifecycle`,
    DOCUMENTS: `${SETTINGS}/documents`,
    ENTITIES: `${SETTINGS}/entities`,
    ORGANISATION: {
      ANY: `${SETTINGS}/organisation/:tab`,
      LIST: `${SETTINGS}/organisation/list`,
      GENERAL: `${SETTINGS}/organisation/general`,
      TEAMS: `${SETTINGS}/organisation/teams`,
      DEPARTMENTS: `${SETTINGS}/organisation/departments`,
    },
    GOALS: {
      ANY: `${SETTINGS}/goals/:tab/:subtab?/:id?`,
      ALL: `${SETTINGS}/goals/all`,
      TARGET_TYPES: `${SETTINGS}/goals/target-types`,
      ROADMAPS: `${SETTINGS}/goals/roadmaps`,
      APPROVAL_PROCESS: `${SETTINGS}/goals/approval-process`,
      TEMPLATES: {
        ANY: `${SETTINGS}/goals/templates/:tab/:id?`,
        LIST: `${SETTINGS}/goals/templates/list`,
        EDIT: `${SETTINGS}/goals/templates/list/:id`,
        NEW: `${SETTINGS}/goals/templates/new`,
      },
      RULES: {
        ANY: `${SETTINGS}/goals/rules/:tab/:id?`,
        LIST: `${SETTINGS}/goals/rules/list`,
        EDIT: `${SETTINGS}/goals/rules/list/:id`,
        NEW: `${SETTINGS}/goals/rules/new`,
      },
    },

    LOCATIONS: `${SETTINGS}/locations`,
    SKILLS: `${SETTINGS}/skills`,
    /**
     * @deprecated use ROLES instead
     */
    FUNCTION: `${SETTINGS}/function`,
    ROLES: {
      ANY: `${SETTINGS}/roles/:tab`,
      GENERAL: `${SETTINGS}/roles/general`,
    },
    COMMUNICATION: `${SETTINGS}/communication`,
    ROADMAPS: `${SETTINGS}/roadmaps`,
    KPI: `${SETTINGS}/kpi`,
    ANALYTICS: `${SETTINGS}/analytics`,
    PERFORMANCE: {
      ANY: `${SETTINGS}/performance/:tab`,
      REVIEW_CYCLES: `${SETTINGS}/performance/review-cycles`,
      PROBATION_AND_PIP: `${SETTINGS}/performance/probation-pip`,
    },
    HELP_CENTER: {
      ANY: `${SETTINGS}/help-center/:tab`,
      GENERAL: `${SETTINGS}/help-center/general`,
      FAQ_CATEGORIES: `${SETTINGS}/help-center/faq-categories`,
    },
    ENGAGEMENT: {
      ANY: `${SETTINGS}/engagement/:subtab`,
      EDIT: `${SETTINGS}/engagement/edit`,
      CHANGELOG: `${SETTINGS}/engagement/changelog`,
    },
    TIME_OFF: {
      ANY: `${SETTINGS}/time-off/:tab/:id?`,
      GENERAL: `${SETTINGS}/time-off/general`,
      REGIMES: `${SETTINGS}/time-off/regimes`,
      HOLIDAYS: `${SETTINGS}/time-off/holidays`,
      CATEGORIES: `${SETTINGS}/time-off/categories`,
      APPROVAL_FLOW: `${SETTINGS}/time-off/approval-flow/:id?`,
      POLICIES: `${SETTINGS}/time-off/policies/:id?`,
    },
    BENEFITS: {
      ANY: `${SETTINGS}/benefits/:tab`,
    },
    TODO: `${SETTINGS}/todo`,
    SCREENING: `${SETTINGS}/screening`,
    REQUISITIONS: {
      ANY: `${SETTINGS}/requisitions/:tab/:id?`,
      LIST: `${SETTINGS}/requisitions/list`,
      GENERAL: `${SETTINGS}/requisitions/general`,
      COUNTRY_HIRING: `${SETTINGS}/requisitions/hiring-countries`,
      COUNTRY_HIRING_FORM: `${SETTINGS}/requisitions/hiring-countries-form/:id`,
      APPROVAL: `${SETTINGS}/requisitions/approval`,
    },
    CANDIDATES: {
      ANY: `${SETTINGS}/candidates/:tab`,
      LIST: `${SETTINGS}/candidates/list`,
      GENERAL: `${SETTINGS}/candidates/general`,
      DATA_RETENTION_SETTINGS: `${SETTINGS}/candidates/data-retention`,
      OFFERS: `${SETTINGS}/candidates/offers`,
      RECRUITMENT_GROUPS: `${SETTINGS}/candidates/recruitment-groups`,
      SCREEN_CALL_SCORECARDS: `${SETTINGS}/candidates/screen-call-scorecards`,
    },
    JOBS: {
      ANY: `${SETTINGS}/jobs/:tab`,
      LIST: `${SETTINGS}/jobs/list`,
    },
    JOB_POSTING: {
      ANY: `${SETTINGS}/job-posting/:tab`,
      LIST: `${SETTINGS}/job-posting/list`,
      GENERAL: `${SETTINGS}/job-posting/general`,
      APPLICATION_FORM_SETTINGS: `${SETTINGS}/job-posting/application-form-settings`,
      CAREER_PAGE: `${SETTINGS}/job-posting/careers-page`,
      APPROVAL: `${SETTINGS}/job-posting/approval`,
    },
    CAREER_PAGE_ONBOARDING: `${SETTINGS}/career-page-onboarding`,
    JOBS_INTEGRATIONS_ONBOARDING: `${SETTINGS}/jobs-integrations-onboarding`,
    PAYROLL: {
      ANY: `${SETTINGS}/payroll/:tab?`,
      GENERAL: `${SETTINGS}/payroll/general`,
      CONFIGURATION: `${SETTINGS}/payroll/configuration`,
    },
    KEY_PERSONS: `${SETTINGS}/key-persons`,
    CLOSE_ACCOUNT: {
      ANY: `${SETTINGS}/close-account/:tab`,
      SURVEY: `${SETTINGS}/close-account/survey`,
      CONFIRMATION: `${SETTINGS}/close-account/confirmation`,
      SCHEDULED_FOR_CLOSURE: `${SETTINGS}/close-account/scheduled-for-closure`,
    },
    COMPENSATION: {
      ANY: `${SETTINGS}/compensation/:tab?`,
      GENERAL: `${SETTINGS}/compensation/general`,
      BANDS: `${SETTINGS}/compensation/bands`,
      BONUS: `${SETTINGS}/compensation/bonus`,
    },
    ONBOARDING: {
      ANY: `${SETTINGS}/employee-onboarding/:tab?`,
      GENERAL: `${SETTINGS}/employee-onboarding/general`,
      ONBOARDING_TEMPLATES: `${SETTINGS}/employee-onboarding/onboarding-templates`,
      EMAILS_TEMPLATES: `${SETTINGS}/employee-onboarding/emails-templates`,
    },
    CUSTOM_FIELDS: `${SETTINGS}/custom-fields`,
  },
  TICKETS: {
    ANY: `${TICKETS}/:id?`,
    ALL: `${TICKETS}`,
    DETAILS: `${TICKETS}/:id`,
  },
  LOGIN: {
    ANY: `${LOGIN}/*`,
    MAIN: LOGIN,
    ONBOARDING: `${LOGIN}/onboarding`,
    /** /password-reset route is used for changing the password which is sent with an email, have to use a different one for the actual reset password route accessed from the UI */
    RESET_PASSWORD: `${LOGIN}/reset-password`,
    REDIRECT: `${LOGIN}/redirect`,
    PASSWORD_CHANGE: `${LOGIN}/password-reset`,
    MAGIC_LINK: `${LOGIN}/onboarding/magicLink`,
  },
  SIGNUP: {
    ANY: `${SIGNUP}/*`,
    MAIN: SIGNUP,
    REDIRECT: `${SIGNUP}/redirect`,
    COMPANY_DETAILS: `${SIGNUP}/company-details`,
    WAITING_LIST: `${SIGNUP}/waiting-list`,
    SUSPENDED: `${SIGNUP}/suspended`,
  },
  TWO_FACTOR_LOGIN: `/two-factor-login`,
  APPS: {
    ANY: `${APPS}/:app/:tab?/:subtab?`,
    PERFORMANCE: {
      ANY: `${APPS}/performance/:tab?/:subtab?`,
      GOALS: {
        ANY: `${APPS}/performance/goals/:subtab?`,
        GENERAL: `${APPS}/performance/goals/general`,
        ROADMAPS: `${APPS}/performance/goals/roadmaps`,
        TARGETS: `${APPS}/performance/goals/targets`,
      },
    },
    EMPLOYEES: {
      ANY: `${APPS}/employees/:tab?/:subtab?/:id?/:type?`,
      ALL: `${APPS}/employees/all`,
      CUSTOM_FIELDS: `${APPS}/employees/custom-fields`,
      BULK_UPDATE_EMPLOYEES: {
        ANY: `${APPS}/employees/bulk-update-employees/:subtab/:id?/:type?`,
        IMPORT: `${APPS}/employees/bulk-update-employees/import`,
        SESSION: `${APPS}/employees/bulk-update-employees/session/:id/:type?`,
      },
      BULK_UPLOAD_EMPLOYEES: {
        ANY: `${APPS}/employees/bulk-upload-employees/:subtab/:id?/:type?`,
        SELECT_FLOW: `${APPS}/employees/bulk-upload-employees/select-flow`,
        IMPORT: `${APPS}/employees/bulk-upload-employees/import`,
        SESSION: `${APPS}/employees/bulk-upload-employees/session/:id/:type?`,
      },
      BULK_UPLOAD_CONTRACTS: {
        ANY: `${APPS}/employees/bulk-upload-contracts/:subtab/:id?/:type?`,
        IMPORT: `${APPS}/employees/bulk-upload-contracts/import`,
        SESSION: `${APPS}/employees/bulk-upload-contracts/session/:id/:type?`,
      },
    },
    LIFECYCLE: {
      ANY: `${APPS}/lifecycle/:tab?`,
      ALL: `${APPS}/lifecycle/all`,
      ONBOARDING: `${APPS}/lifecycle/onboarding`,
      ONBOARDING_EMPLOYEES: `${APPS}/lifecycle/onboarding-employees`,
      OFFBOARDING: `${APPS}/lifecycle/offboarding`,
      RESIGNATIONS: `${APPS}/lifecycle/resignations`,
    },
    TEAMS: {
      ANY: `${APPS}/teams/:tab?`,
      TEAMS: `${APPS}/teams/teams`,
      DEPARTMENTS: `${APPS}/teams/departments`,
      COMPANY: `${APPS}/teams/company`,
    },
    POSITIONS: {
      ANY: `${APPS}/positions/:tab?`,
      SPECIALISATIONS: `${APPS}/positions/specialisations`,
      ROLES: `${APPS}/positions/roles`,
      FUNCTIONS: `${APPS}/positions/functions`,
      SENIORITIES: `${APPS}/positions/seniorities`,
      SKILLS: `${APPS}/positions/skills`,
    },
    COMPENSATION: {
      ANY: `${APPS}/compensation/:tab?`,
      BANDS: `${APPS}/compensation/bands`,
      BUDGET_POOLS: `${APPS}/compensation/budget-pools`,
      REVIEW_CYCLES: `${APPS}/compensation/review-cycles`,
      BONUSES: `${APPS}/compensation/bonuses`,
      SETTINGS: `${APPS}/compensation/settings`,
      OPTION_EXERCISE: `${APPS}/compensation/option-exercise`,
    },
    DOCUMENTS: {
      ANY: `${APPS}/documents/:tab/:subtab?/:id?`,
      DOCUMENTS: `${APPS}/documents/documents`,
      CATEGORIES: `${APPS}/documents/categories`,
      RULES: `${APPS}/documents/rules`,
      BULK_REQUEST: {
        ANY: `${APPS}/documents/bulk-request/:action/:tab/:id?`,
        LIST: `${APPS}/documents/bulk-request/list/all`,
        PREVIEW: `${APPS}/documents/bulk-request/preview/all/:id?`,
        EDIT: {
          ANY: `${APPS}/documents/bulk-request/edit/:tab/:id?`,
          BASICS: `${APPS}/documents/bulk-request/edit/basics/:id?`,
          TEMPLATES: `${APPS}/documents/bulk-request/edit/templates/:id?`,
          REVIEW: `${APPS}/documents/bulk-request/edit/review/:id?`,
        },
      },
      TEMPLATES: {
        ANY: `${APPS}/documents/templates/:action/:tab/:id?`,
        LIST: `${APPS}/documents/templates/list/all`,
        PREVIEW: `${APPS}/documents/templates/preview/all/:id`,
        EDIT: {
          ANY: `${APPS}/documents/templates/edit/:tab/:id?`,
          BASICS: `${APPS}/documents/templates/edit/basics/:id?`,
          FILE: `${APPS}/documents/templates/edit/file/:id`,
        },
      },
      COLLECTIONS: `${APPS}/documents/collections`,
      E_SIGNATURE_TEMPLATES: {
        ANY: `${APPS}/documents/e-sig-templates/:action/:tab/:id?`,
        EDIT: {
          ANY: `${APPS}/documents/e-sig-templates/edit/:tab/:id?`,
          BASICS: `${APPS}/documents/e-sig-templates/edit/basics/:id?`,
          FILE: `${APPS}/documents/e-sig-templates/edit/file/:id`,
        },
      },
    },
    BENEFITS: {
      ANY: `${APPS}/benefits/:tab`,
      ENROLMENT: `${APPS}/benefits/enrolment`,
      TEMPLATES: `${APPS}/benefits/templates`,
    },
    ENGAGEMENT: {
      ANY: `${APPS}/engagement/:subtab/:id?`,
      DASHBOARD: `${APPS}/engagement/dashboard`,
      DETAILS_OVERVIEW: `${APPS}/engagement/overview/:id`,
      DETAILS_RESULTS: {
        ANY: `${APPS}/engagement/results/:id/:type?`,
        CATEGORIES: `${APPS}/engagement/results/:id/categories`,
        COMMENTS: `${APPS}/engagement/results/:id/comments`,
        QUESTIONS: `${APPS}/engagement/results/:id/questions`,
      },
      SURVEY: {
        ANY: `${APPS}/engagement/survey/:action/:id?`,
        EDIT: `${APPS}/engagement/survey/edit/:id?`,
        PREVIEW: `${APPS}/engagement/survey/preview/:id`,
        HISTORY: `${APPS}/engagement/survey/history/:id`,
      },
      SURVEYS_TABLE: `${APPS}/engagement/surveys`,
      DRIVER: {
        ANY: `${APPS}/engagement/driver/:action/:id?`,
        EDIT: `${APPS}/engagement/driver/edit/:id?`,
        PREVIEW: `${APPS}/engagement/driver/preview/:id`,
      },
      DRIVERS_TABLE: `${APPS}/engagement/drivers`,
      QUESTION: {
        ANY: `${APPS}/engagement/question/:action/:id?`,
        EDIT: `${APPS}/engagement/question/edit/:id?`,
        PREVIEW: `${APPS}/engagement/question/preview/:id`,
      },
      QUESTIONS_TABLE: `${APPS}/engagement/questions`,
      RESPONSES: `${APPS}/engagement/responses`,
      SETTINGS_CHANGELOG: `${APPS}/engagement/settings-changelog/:id?`,
    },
    KEY_PERSONS: {
      ANY: `${APPS}/key-persons/:tab/:action?/:id?`,
      RESPONSIBILITIES_TABLE: `${APPS}/key-persons/all-responsibilities`,
      RESPONSIBILITY: {
        ANY: `${APPS}/key-persons/responsibility/:action/:id?`,
        PREVIEW: `${APPS}/key-persons/responsibility/view/:id`,
        EDIT: `${APPS}/key-persons/responsibility/edit/:id?`,
      },
      RESPONSIBILITIES_ASSIGNMENTS_TABLE: `${APPS}/key-persons/all-responsibilities-assignments`,
      SUCCESSION_PLANS_TABLE: `${APPS}/key-persons/all-succession-plans`,
      SUCCESSION_PLAN: {
        ANY: `${APPS}/key-persons/succession-plan/:action/:id?/:subtab?`,
        PREVIEW: {
          ANY: `${APPS}/key-persons/succession-plan/view/:id?/:subtab?`,
          DETAILS: `${APPS}/key-persons/succession-plan/view/:id`,
          CHANGELOG: `${APPS}/key-persons/succession-plan/view/:id/changelog`,
        },
        EDIT: `${APPS}/key-persons/succession-plan/edit/:id?`,
      },
    },
    INTERVIEWS: `${APPS}/interviews`,
    CANDIDATES: `${APPS}/candidates`,
    JOBS: {
      ANY: `${APPS}/jobs/:tab`,
      REQUISITIONS: `${APPS}/jobs/requisitions`,
      JOB_POSTINGS: `${APPS}/jobs/job-postings`,
    },
    GROUPS: `${APPS}/groups`,
    LOCATIONS: `${APPS}/locations`,
    ENTITIES: `${APPS}/entities`,
    TODO: {
      ANY: `${APPS}/todo/:subtab?`,
      ALL: `${APPS}/todo/all`,
      REVIEWS: `${APPS}/todo/reviews`,
      MEETINGS: `${APPS}/todo/meetings`,
      UPWARDS_REVIEW: `${APPS}/todo/upwards-reviews`,
      PIP: `${APPS}/todo/pip`,
      PROBATION: `${APPS}/todo/probation`,
      KPIS: `${APPS}/todo/kpis`,
      KPIS_UPLOAD: `${APPS}/todo/kpis_bulk_upload`,
      KPIS_TO_ASSIGN: `${APPS}/todo/kpis-to-assign`,
      KPIS_TO_APPROVE: `${APPS}/todo/kpis-to-approve`,
      GOALS_TO_SET: `${APPS}/todo/goals-to-set`,
      GOALS_TO_APPROVE: `${APPS}/todo/goals-to-approve`,
      GOALS_TO_CALIBRATE: `${APPS}/todo/goals-to-calibrate`,
      REQUISITIONS: `${APPS}/todo/requisitions`,
      PENDING_DOCUMENTS: `${APPS}/todo/pending-documents`,
      ROLES: `${APPS}/todo/roles`,
      TRAININGS: `${APPS}/todo/trainings`,
      REVIEW_CALIBRATION: `${APPS}/todo/review-calibration`,
      DATA_ACCESS: `${APPS}/todo/data-access`,
      SERVICE_DESK: `${APPS}/todo/service-desk`,
      FINDINGS: `${APPS}/todo/findings`,
      SKILLS: `${APPS}/todo/skills`,
      DELIVERABLES: `${APPS}/todo/deliverables`,
      INTERVIEWS: `${APPS}/todo/interviews`,
      CATCHUP_INTERVIEWS: `${APPS}/todo/catchup-interviews`,
      CANDIDATES: `${APPS}/todo/candidates`,
      OWNERSHIP: `${APPS}/todo/ownership`,
      JOB_POSTINGS: `${APPS}/todo/job-postings`,
      TIME_OFF: `${APPS}/todo/time-off`,
      BULK_UPLOAD: `${APPS}/todo/bulk-upload`,
      GROUP: `${APPS}/todo/group`,
      SUCCESSION_PLANS: `${APPS}/todo/succession-plans`,
      GROUP_ACCESS_REQUEST: `${APPS}/todo/group-access-request`,
      COMMENTS: `${APPS}/todo/comments`,
      PROMOTION: `${APPS}/todo/promotion`,
    },
    DATA_RETENTION: {
      ANY: `${APPS}/data-retention/:tab/:countryId?/:id?`,
      EMPLOYMENT: `${APPS}/data-retention/employment-policies`,
      RECRUITMENT: `${APPS}/data-retention/recruitment-policies`,
    },
    COMMUNICATION: {
      ANY: `${APPS}/communication/:tab`,
      NOTIFICATIONS: `${APPS}/communication/notifications`,
      GROUPS: `${APPS}/communication/groups`,
      EMAIL_TEMPLATES: `${APPS}/communication/email-templates`,
    },
    TIME_MANAGEMENT: {
      ANY: `${APPS}/time-management/:feature?/:tab?/:subtab?/:id?`,
      TIME_MANAGEMENT: `${APPS}/time-management`,
      TIME_OFF: {
        ANY: `${APPS}/time-management/time-off/:subtab`,
        POLICIES: `${APPS}/time-management/time-off/policies`,
        REQUESTS: `${APPS}/time-management/time-off/requests`,
      },
      WORK_SCHEDULES: {
        ANY: `${APPS}/time-management/work-schedules/:tab?/:subtab?/:id?`,
        LIST: `${APPS}/time-management/work-schedules/list`,
        PREVIEW: `${APPS}/time-management/work-schedules/preview/details/:id`,
        EDIT: {
          ANY: `${APPS}/time-management/work-schedules/edit/:subtab?/:id?`,
          BASICS: `${APPS}/time-management/work-schedules/edit/basics/:id?`,
          EXTRA_HOURS: `${APPS}/time-management/work-schedules/edit/extra-hours/:id?`,
          APPROVALS: `${APPS}/time-management/work-schedules/edit/approvals/:id?`,
          ELIGIBILITY: `${APPS}/time-management/work-schedules/edit/eligibility/:id?`,
          REVIEW: `${APPS}/time-management/work-schedules/edit/review/:id?`,
        },
      },
      WORK_SCHEDULES_CUSTOM_APPROVERS: {
        ANY: `${APPS}/time-management/work-schedules-custom-approvers/:tab?/:subtab?/:id?`,
        LIST: `${APPS}/time-management/work-schedules-custom-approvers/list`,
        PREVIEW: `${APPS}/time-management/work-schedules-custom-approvers/preview/details/:id`,
        EDIT: {
          ANY: `${APPS}/time-management/work-schedules-custom-approvers/edit/:subtab/:id?`,
          DETAILS: `${APPS}/time-management/work-schedules-custom-approvers/edit/details/:id?`,
          REVIEW: `${APPS}/time-management/work-schedules-custom-approvers/edit/review/:id?`,
        },
      },
    },
    CONTRACTS: {
      ANY: `${APPS}/contracts/:subtab`,
      ALL: `${APPS}/contracts/all`,
      TYPES: `${APPS}/contracts/types`,
    },
    ATTENDANCE: {
      ANY: `${APPS}/attendance/:subtab`,
      POLICIES: `${APPS}/attendance/policies`,
      SCHEDULER: `${APPS}/attendance/scheduler`,
      SHIFT_SUMMARY: `${APPS}/attendance/shift-summary`,
      SHIFT_BREAKDOWN: `${APPS}/attendance/shift-breakdown`,
    },
    SCREENING: {
      ANY: `${APPS}/screening/:tab`,
      SCREENING_TABLE: `${APPS}/screening/all-screening-checks`,
    },
    PAYROLL_V2: {
      ANY: `${APPS}/payroll-v2/:tab/:subtab?/:id?`,
      PAY_GROUP: {
        ANY: `${APPS}/payroll-v2/pay-group/:subtab?/:id?`,
        LIST: `${APPS}/payroll-v2/pay-group/all`,
      },
      PAY_CYCLE: {
        ANY: `${APPS}/payroll-v2/pay-cycle/:subtab?/:id?`,
        OVERVIEW: `${APPS}/payroll-v2/pay-cycle/overview/:id`,
        CHANGES: `${APPS}/payroll-v2/pay-cycle/changes/:id`,
        COMMENTS: `${APPS}/payroll-v2/pay-cycle/comments/:id`,
      },
    },
    PAYROLL: {
      ANY: `${APPS}/payroll/:tab/:subtab?/:id?`,
      PAY_CYCLE: {
        ANY: `${APPS}/payroll/pay-cycles/:subtab/:id?`,
        REPORTS: `${APPS}/payroll/pay-cycles/reports/:id`,
        UPLOAD: `${APPS}/payroll/pay-cycles/upload/:id`,
        UPLOAD_FILE: `${APPS}/payroll/pay-cycles/upload-file/:id`,
        TIMELINE: `${APPS}/payroll/pay-cycles/timeline/:id`,
        REVIEW: `${APPS}/payroll/pay-cycles/preview/:id/:sessionId`,
        PREVIEW: `${APPS}/payroll/pay-cycles/view/:id/:employeeId`,
        STATUS: `${APPS}/payroll/pay-cycles/status/:id/:sessionId`,
      },
      PAY_CYCLES_TABLE: `${APPS}/payroll/pay-cycles`,
      PAY_CYCLES_DOWNLOAD_REPORT: `${APPS}/payroll/pay-cycles/download-reports/:id/:action/:requestId`,
      PAY_CYCLES_DOWNLOAD_REPORTS_TABLE: {
        ANY: `${APPS}/payroll/pay-cycles/download-reports-table/:id/:action`,
        DOWNLOAD: `${APPS}/payroll/pay-cycles/download-reports-table/:id/download`,
        UPLOAD: `${APPS}/payroll/pay-cycles/download-reports-table/:id/upload`,
      },

      PAY_GROUP: {
        ANY: `${APPS}/payroll/pay-groups/:subtab/:id?`,
        EDIT: `${APPS}/payroll/pay-groups/edit/:id?`,
        PREVIEW: `${APPS}/payroll/pay-groups/view/:id`,
      },
      PAYMENTS_TABLE: `${APPS}/payroll/payments`,
      SCHEDULE_PAYMENT: `${APPS}/payroll/new-payment`,
      RECONCILE_PAYMENT: `${APPS}/payroll/reconcile-payment`,
      BULK_DELETE_PAYMENTS: `${APPS}/payroll/bulk-delete-payments`,
    },
    DATA_ANALYTICS: {
      ANY: `${APPS}/data-analytics/:tab`,
      DASHBOARD: `${APPS}/data-analytics/dashboard`,
      REPORTS: `${APPS}/data-analytics/reports`,
      QUERIES: `${APPS}/data-analytics/queries`,
      CONNECTIONS: `${APPS}/data-analytics/connections`,
    },
    HELP_CENTER: {
      ANY: `${APPS}/help-center/:tab/:action?/:id?`,
      FAQ_TABLE: `${APPS}/help-center/all-faq`,
      FAQ_IMPORT: {
        ANY: `${APPS}/help-center/import-faq/:subtab/:id?`,
        UPLOAD: `${APPS}/help-center/import-faq/upload`,
        SESSION: `${APPS}/help-center/import-faq/session/:id`,
      },
      FAQ_TOPICS_TABLE: `${APPS}/help-center/all-faq-topics`,
      SD_CATEGORIES_TABLE: `${APPS}/help-center/sd-categories`,
      SD_CATEGORY: {
        ANY: `${APPS}/help-center/sd-category/:action?/:id?`,
        PREVIEW: `${APPS}/help-center/sd-category/preview/:id`,
        EDIT: `${APPS}/help-center/sd-category/edit/:id?`,
      },
      QUESTION: {
        ANY: `${APPS}/help-center/question/:action?/:id?`,
        PREVIEW: `${APPS}/help-center/question/preview/:id`,
        EDIT: `${APPS}/help-center/question/edit/:id?`,
      },
      TOPIC: {
        ANY: `${APPS}/help-center/topic/:action?/:id?`,
        PREVIEW: `${APPS}/help-center/topic/preview/:id`,
        EDIT: `${APPS}/help-center/topic/edit/:id?`,
      },
    },
  },
  PLATFORM_STATUS: '/status',
  GRANT_PERMISSIONS_REDIRECT: '/grant-email-permissions-redirect',
  ENGAGEMENT_PAGE: '/feedback',
  SANDBOX: '/sandbox',
  CLOSE_POPUP: '/close-popup',
  REVBETS: '/revbets',
  CONTACT_US: '/contact-us',
  PAGE_404: '/404',
}

export const PUBLIC_ROUTES = {
  ANY: `${PUBLIC_PREFIX}/*`,
  CANDIDATE_SCHEDULING: {
    ANY: `${PUBLIC_PREFIX}/candidate-scheduling/:tab`,
    GENERAL: `${PUBLIC_PREFIX}/candidate-scheduling/general`,
    SELECT: `${PUBLIC_PREFIX}/candidate-scheduling/select`,
  },
  WELCOME: `${PUBLIC_PREFIX}/welcome`,
  CAREERS: {
    ANY: `${PUBLIC_PREFIX}${CAREERS}/:tab?/:id?`,
    JOB_LIST: `${PUBLIC_PREFIX}${CAREERS}`,
    /** @deprecated */
    JOB_LIST_DEPRECATED: `${PUBLIC_PREFIX}${CAREERS}/job-list`,
    APPLICATION: `${PUBLIC_PREFIX}${CAREERS}/apply/:id`,
    POSITION: `${PUBLIC_PREFIX}${CAREERS}/position/:id`,
  },
  BOOKING_LINK: {
    ANY: `${PUBLIC_PREFIX}/book/:tab/:token`,
    GENERAL: `${PUBLIC_PREFIX}/book/g/:token`,
    SELECT: `${PUBLIC_PREFIX}/book/select/:token`,
  },
  ENGAGEMENT_CONSENT: `${PUBLIC_PREFIX}/engagement-consent/:token`,
  DIVERSITY_SURVEY: `${PUBLIC_PREFIX}/applicant-diversity/general`,
  TERMS_AND_CONDITIONS: `${PUBLIC_PREFIX}/terms-and-conditions`,
  COMPLAINTS: `${PUBLIC_PREFIX}/complaints`,
  COMING_SOON: `${PUBLIC_PREFIX}/coming-soon`,
  PRIVACY_NOTICE: `${PUBLIC_PREFIX}/privacy-notice`,
  SUBDOMAIN_PERMISSIONS_REDIRECT: `${PUBLIC_PREFIX}/subdomain-permissions-redirect`,
  PAGE_404: `${PUBLIC_PREFIX}/404`,
}
