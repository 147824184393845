import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  CandidateHiringStageRow,
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { selectorKeys } from '@src/constants/api'
import { FeedbackInterviewer } from '@src/pages/Forms/Candidate/StagesWidget/components/FeedbackInterviewer'
import { FeedbackResult } from '@src/pages/Forms/Candidate/StagesWidget/components/FeedbackResult'
import { FeedbackRating } from '@src/pages/Forms/Candidate/StagesWidget/components/FeedbackRating'
import { StageStatus } from '@src/pages/Forms/Candidate/StagesWidget/components/StageStatus'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import FeedbackActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/FeedbackActions'
import { isFeedback } from '@src/pages/Forms/Candidate/StagesWidget/utils'

export const candidateHiringStagesTitle: ColumnInterface<CandidateHiringStageRow> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
  insert: ({ data }) => {
    if (isFeedback(data)) {
      return <FeedbackInterviewer feedback={data} />
    }
    return <>{data.stage.title}</>
  },
}

export const candidateHiringStagesRecommendation: ColumnInterface<CandidateHiringStageRow> =
  {
    type: CellTypes.insert,
    idPoint: 'recommendation',
    dataPoint: 'recommendation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Recommendation',
    insert: ({ data }) => {
      if (isFeedback(data)) {
        return <FeedbackResult feedback={data} />
      }
      return null
    },
  }

export const createCandidateHiringStagesRating = (
  round: InterviewRoundInterface,
): ColumnInterface<CandidateHiringStageRow> => ({
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rating',
  insert: ({ data }) => {
    if (isFeedback(data)) {
      return <FeedbackRating feedback={data} />
    }
    return <StageStatus round={round} stage={data.stage} />
  },
})

export const createCandidateHiringStagesActions = (
  round: InterviewRoundInterface,
  candidate: CandidateInterface,
  stages: InterviewStageWithoutRoundInterface[],
  canAddFeedback: boolean,
  canCancel: boolean,
  canViewEditOffer: boolean,
  disableActions: boolean,
  onRefresh: () => void,
  onClick?: StagesWidgetOnClickHandler,
): ColumnInterface<CandidateHiringStageRow> => ({
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: '',
  insert: ({ data }) => {
    const isArchived = !!round?.archived_reason
    const showActions = !disableActions && (canCancel || canAddFeedback) && !isArchived
    if (!showActions) {
      return null
    }
    if (isFeedback(data)) {
      return (
        <FeedbackActions
          feedback={data}
          canCancel={canCancel}
          canAddFeedback={canAddFeedback}
          onRefresh={onRefresh}
        />
      )
    }
    return (
      <StageActions
        canAddFeedback={canAddFeedback}
        canCancel={canCancel}
        candidateId={candidate.id}
        canViewEditOffer={canViewEditOffer}
        currentStageId={round?.latest_interview_stage?.id}
        menuType="dropdown"
        roundId={round.id}
        stage={data.stage}
        stages={stages}
        onOpenSidebar={(stageData, mode, actionType) => {
          onClick?.(stageData, mode, undefined, actionType)
        }}
        onRefresh={onRefresh}
      />
    )
  },
})
