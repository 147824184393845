import React, { useMemo, useState } from 'react'
import { Token, useToggle, VStack, Widget } from '@revolut/ui-kit'
import { HiringStageSwitcher } from '@src/pages/Forms/Candidate/StagesWidget/HiringStageSwitcher'
import {
  CandidateInterface,
  InterviewRoundInterface,
  CandidateHiringStageRow,
  InterviewStageWithoutRoundInterface,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { FilterBar } from '@src/pages/Forms/Candidate/StagesWidget/FilterBar'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  candidateHiringStagesTitle,
  candidateHiringStagesRecommendation,
  createCandidateHiringStagesRating,
  createCandidateHiringStagesActions,
} from '@src/constants/columns/candidateHiringStages'
import { PermissionTypes } from '@src/store/auth/types'
import { isFeedback } from './utils'

const ROW = (
  round: InterviewRoundInterface,
  candidate: CandidateInterface,
  stages: InterviewStageWithoutRoundInterface[],
  canAddFeedback: boolean,
  canCancel: boolean,
  canViewEditOffer: boolean,
  disableActions: boolean,
  onRefresh: () => void,
  onClick?: StagesWidgetOnClickHandler,
): RowInterface<CandidateHiringStageRow> => ({
  noChildrenRequest: true,
  highlight: (_, parentIndexes) => {
    return parentIndexes[0] % 2 === 0
      ? Token.color.groupedBackground
      : Token.color.widgetBackground
  },
  cells: [
    { ...candidateHiringStagesTitle, width: 100 },
    { ...candidateHiringStagesRecommendation, width: 40 },
    { ...createCandidateHiringStagesRating(round), width: 40 },
    {
      ...createCandidateHiringStagesActions(
        round,
        candidate,
        stages,
        canAddFeedback,
        canCancel,
        canViewEditOffer,
        disableActions,
        onRefresh,
        onClick,
      ),
      width: 20,
    },
  ],
})

const getAssessmentOnlyStages = (stages?: InterviewStageWithoutRoundInterface[]) => {
  const assessmentTypes = [
    'home_assessment',
    'skills_round',
    'online_test',
    'test_review',
    'final_round',
  ]
  return (stages || [])?.filter(({ interview_type }) =>
    assessmentTypes.includes(interview_type),
  )
}

type StagesWidgetProps = {
  candidate?: CandidateInterface
  canViewEditOffer: boolean
  disableActions: boolean
  loading: boolean
  round?: InterviewRoundInterface
  stages: InterviewStageWithoutRoundInterface[]
  onClick?: StagesWidgetOnClickHandler
  onRefresh: () => void
  onRoundChange: (roundId: number) => void
}

export const StagesWidget = ({
  candidate,
  canViewEditOffer,
  disableActions,
  loading,
  round,
  stages,
  onClick,
  onRefresh,
  onRoundChange,
}: StagesWidgetProps) => {
  const [assessmentOnly, changeAssessmentOnly] = useToggle()
  const [stagesFilter, setStagesFilter] = useState<number[]>([])
  const canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  const filteredStages = useMemo(() => {
    return assessmentOnly ? getAssessmentOnlyStages(stages) : stages || []
  }, [assessmentOnly, stages])
  const displayStages = useMemo(() => {
    if (stagesFilter.length) {
      return filteredStages.filter(({ id }) => stagesFilter.includes(id))
    }
    return filteredStages
  }, [filteredStages, stagesFilter])
  const withChildren = useMemo(() => {
    return displayStages.map(stage =>
      stage.interview_feedbacks
        ? { stage, children: stage.interview_feedbacks }
        : { stage },
    )
  }, [displayStages])
  if (!round || !candidate) {
    return null
  }
  return (
    <VStack gap="s-16" data-testid="Stages widget">
      <HiringStageSwitcher candidate={candidate} round={round} onChange={onRoundChange} />
      <FilterBar
        assessmentOnly={assessmentOnly}
        stages={filteredStages}
        stagesFilter={stagesFilter}
        onAssessmentOnlyChange={() => {
          changeAssessmentOnly(!assessmentOnly)
        }}
        onStagesFilterChange={newStagesFilter => {
          setStagesFilter(newStagesFilter)
        }}
      />
      <Widget>
        <AdjustableTable<CandidateHiringStageRow>
          childrenOpenByDefault
          count={withChildren?.length}
          data={withChildren}
          dataType="Hiring stage"
          enableSettings={false}
          expandableType="chevron"
          hideCount
          loading={loading}
          lockFirstColumn={false}
          name={TableNames.CandidateHiringStages}
          noDataMessage="There are no interviews for this candidate"
          noReset
          row={ROW(
            round,
            candidate,
            displayStages,
            canAddFeedback,
            canCancel,
            canViewEditOffer,
            disableActions,
            onRefresh,
            onClick,
          )}
          onRowClick={row => {
            const data = isFeedback(row) ? row : row.stage
            onClick?.(data, undefined, data.interview_type)
          }}
        />
      </Widget>
    </VStack>
  )
}
